/* eslint-disable no-unused-vars */
import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"
import News from "../views/news/index.vue"
import NewsDetail from "../views/news/id/index.vue"
import Users from "../views/contacts/index.vue"
import UsersDetail from "../views/contacts/id/index.vue"
import UserGroup from "../views/user-group/index.vue"
import UserGroupDetail from "../views/user-group/id/index.vue"
import UserPosition from "../views/user-position/index.vue"
import UserPositionDetail from "../views/user-position/id/index.vue"
import UserCompany from "../views/user-company/index.vue"
import UserCompanyDetail from "../views/user-company/id/index.vue"
import UserDepartment from "../views/user-department/index.vue"
import UserDepartmentDetail from "../views/user-department/id/index.vue"
import UserApplication from "../views/user-applications/index.vue"
import CompanyManagement from "../views/company-management/index.vue"
import CompanyManagementDetail from "../views/company-management/id/index.vue"
import RoleManagement from "../views/role-management/index.vue"
import ApplicationManagement from "../views/application-management/index.vue"
import ExpenseCategories from "../views/expense-categories/index.vue"
import RevenueCategories from "../views/revenue-categories/index.vue"
import ExpenseAndRevenueConfig from "../views/expense-config/index.vue"
import ExpenseAndRevenueConfigDetail from "../views/expense-config/id/index.vue"
import WarehouseConfig from "../views/warehouse-config/index.vue"
import WarehouseConfigDetail from "../views/warehouse-config/id/index.vue"
import WarehouseManagementTeams from "../views/warehouse-management/teams/index.vue"
import WarehouseManagementLocations from "../views/warehouse-management/locations/index.vue"
import WarehouseManagementCustomersAccount from "../views/warehouse-management/customer-accounts/index.vue"
import Login from "../views/Login.vue"
import api from "@/services"
import { getCookie, deleteCookie } from "@/helpers/customizeCookie.js"
import auth from "../middleware/auth.js"
import store from "@/store"

Vue.use(VueRouter)
const VUE_APP_TITLE = process.env.VUE_APP_TITLE

const routes = [
  {
    path: "/",
    name: "news",
    component: News,
  },
  {
    path: "/news/search",
    name: "news-search",
    component: News,
  },
  {
    path: "/news/:id",
    name: "news-detail",
    component: NewsDetail,
  },

  {
    path: "/schedules",
    name: "schedules",
    component: Home,
  },
  {
    path: "/users",
    name: "users",
    component: Users,
  },
  {
    path: "/users/:id",
    name: "users-detail",
    component: UsersDetail,
  },
  {
    path: "/user-group",
    name: "user-group",
    component: UserGroup,
  },
  {
    path: "/user-group/:id",
    name: "user-group-detail",
    component: UserGroupDetail,
  },
  {
    path: "/user-company",
    name: "user-company",
    component: UserCompany,
  },
  {
    path: "/user-company/:id",
    name: "user-company-detail",
    component: UserCompanyDetail,
  },
  {
    path: "/user-department",
    name: "user-department",
    component: UserDepartment,
  },
  {
    path: "/user-department/:id",
    name: "user-department-detail",
    component: UserDepartmentDetail,
  },
  {
    path: "/user-position",
    name: "user-position",
    component: UserPosition,
  },
  {
    path: "/user-position/:id",
    name: "user-position-detail",
    component: UserPositionDetail,
  },
  {
    path: "/user-application",
    name: "user-application",
    component: UserApplication,
  },
  {
    path: "/company-management",
    name: "company-management",
    component: CompanyManagement,
  },
  {
    path: "/company-management/:id",
    name: "company-management-detail",
    component: CompanyManagementDetail,
  },

  {
    path: "/role-management",
    name: "role-management",
    component: RoleManagement,
  },
  {
    path: "/application-management",
    name: "application-management",
    component: ApplicationManagement,
  },
  {
    path: "/expense-categories",
    name: "expense-categories",
    component: ExpenseCategories,
  },
  {
    path: "/revenue-categories",
    name: "revenue-categories",
    component: RevenueCategories,
  },
  {
    path: "/expense-config",
    name: "expense-config",
    component: ExpenseAndRevenueConfig,
  },
  {
    path: "/expense-config/:id",
    name: "expense-config-detail",
    component: ExpenseAndRevenueConfigDetail,
  },
  {
    path: "/warehouse-config",
    name: "warehouse-config",
    component: WarehouseConfig,
  },
  {
    path: "/warehouse-config/:id",
    name: "warehouse-config-detail",
    component: WarehouseConfigDetail,
  },
  {
    path: "/warehouse-management/teams",
    name: "warehouse-management-teams",
    component: WarehouseManagementTeams,
  },
  {
    path: "/warehouse-management/locations",
    name: "warehouse-management-locations",
    component: WarehouseManagementLocations,
  },
  {
    path: "/warehouse-management/customer-accounts",
    name: "warehouse-management-customer-accounts",
    component: WarehouseManagementCustomersAccount,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach(async (to, from, next) => {
  if (to?.meta?.title) {
    document.title = to.meta.title
  }
  if (VUE_APP_TITLE) {
    document.title = VUE_APP_TITLE
  }
  if (to.path.includes("/login")) {
    if (auth()) {
      // alert("x1 - 1")
      const userAuth = store.state.auth
      if (userAuth.isAuth && userAuth.user) {
        next()
        return
      }
      setTimeout(async () => {
        const localCToken = getCookie("token")
        const res = await api.user.getUserInfo(localCToken)
        if (!res) {
          localStorage.removeItem("token")
          deleteCookie("token")
          next({
            path: "/login",
            query: { redirect: to.fullPath },
          })
        }
        try {
          if (res.status > 399) {
            localStorage.removeItem("token")
            deleteCookie("token")

            next({
              path: "/login",
              query: { redirect: to.fullPath },
            })

            return
          }
          if (res.response && !res.response.data.success) {
            localStorage.removeItem("token")
            deleteCookie("token")
            next({
              path: "/login",
              query: { redirect: to.fullPath },
            })
            return
          }
          const localCToken = getCookie("token")
          const localLToken = localStorage.getItem("token")
          const localToken = localCToken || localLToken
          const auth_set = {
            isAuth: true,
            user: res.data.data.user,
            token: `Bearer ${localToken}`,
          }
          store.commit("SET_USER_LOGGEDIN", auth_set)
          const nextStep =
            to.query && to.query.redirect ? to.query.redirect : "/"
          next({
            path: String(nextStep),
          })
        } catch (error) {
          console.log(error)
          next({
            path: "/login",
            query: { redirect: to.fullPath },
          })
        }
      }, 1000)
    } else {
      next()
    }
    return
  } else {
    if (auth()) {
      const userAuth = store.state.auth
      if (userAuth && userAuth.isAuth && userAuth.user) {
        next()
        return
      }
      setTimeout(async () => {
        const localCToken = getCookie("token")
        const res = await api.user.getUserInfo(localCToken)
        if (!res) {
          localStorage.removeItem("token")
          deleteCookie("token")

          next({
            path: "/login",
            query: { redirect: to.fullPath },
          })
        }
        try {
          if (res.status > 399) {
            localStorage.removeItem("token")
            deleteCookie("token")

            next({
              path: "/login",
              query: { redirect: to.fullPath },
            })
            return
          }
          if (res.response && !res.response.data.success) {
            localStorage.removeItem("token")
            deleteCookie("token")

            next({
              path: "/login",
              query: { redirect: to.fullPath },
            })
            return
          }
          const localCToken = getCookie("token")
          const localLToken = localStorage.getItem("token")
          const localToken = localCToken || localLToken
          const auth_set = {
            isAuth: true,
            user: res.data.data.user,
            token: `Bearer ${localToken}`,
          }
          store.commit("SET_USER_LOGGEDIN", auth_set)
          // const nextStep = to.query && to.query.redirect ? to.query.redirect : "/";
          const nextStep = to.fullPath
          next({
            path: String(nextStep),
          })
        } catch (error) {
          console.log(error)

          next({
            path: "/login",
            query: { redirect: to.fullPath },
          })
        }
      }, 1000)
    } else {
      localStorage.removeItem("token")
      deleteCookie("token")

      next({
        path: "/login",
        query: { redirect: to.fullPath },
      })
    }
  }
})
export default router
