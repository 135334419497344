<template>
  <div
    class="news-container m-0 px-3 py-4 content-slot-container white box-shadow--mod-lighter"
  >
    <div class="content-slot-header">
      Cấu hình thu chi
      <div class="d-flex gap-2">
        <v-btn
          color="primary"
          depressed
          style="border-radius: 8px"
          class="low-letter-spacing"
          @click="showListImportConfigFile = true"
        >
          File chờ xử lí
        </v-btn>
        <v-btn
          color="primary"
          depressed
          style="border-radius: 8px"
          class="low-letter-spacing"
          @click="showImportConfigFile = true"
        >
          Thêm file
        </v-btn>
        <v-btn
          color="primary"
          depressed
          style="border-radius: 8px"
          class="low-letter-spacing"
          @click="showCreateData = true"
        >
          Thêm cấu hình
        </v-btn>
      </div>
    </div>
    <div class="content-slot-content mx-auto w-100">
      <v-row class="m-0 align-end filter-wr no-wrap">
        <v-col cols="3">
          <div class="each-filter">
            <p class="mb-1 filter-name neutral--text">Tìm kiếm</p>
            <div class="filter-input">
              <input
                v-model="contactSearchQuery"
                type="text"
                :placeholder="sysLanguage.placeholder.textSearch"
                class="input-no-focus font-size-14"
              />
              <!-- <v-icon color="neutral" size="20">mdi-magnify</v-icon> -->
            </div>
          </div></v-col
        >
        <v-col cols="auto" class="ml-auto">
          <div class="panigate-block px-2">
            <Pagination
              :loading="loadingSearch"
              class="mt-0"
              :pagination-sync="pagination"
              @handlePageSizeChange="pagePaginationChange"
              @handlePageChange="pagePaginationChange"
            /></div
        ></v-col>
      </v-row>

      <div class="table-wrapper white">
        <ExpenseConfigTable
          :list-data="listDataComputed"
          :isFirstLoading="isFirstLoading"
          :loadingTable="loadingSearch"
          :viewDetail="viewDetail"
          :updateStatus="updateStatus"
        />
      </div>
    </div>

    <DialogListImportExpenseConfig
      :isVisible="showListImportConfigFile"
      :cancelAction="
        () => {
          showListImportConfigFile = false
        }
      "
    />
    <DialogCreateExpenseConfig
      :isVisible="showCreateData"
      :cancelAction="cancelCreateData"
      :confirmAction="addData"
    />
    <DialogImportExpenseConfig
      :isVisible="showImportConfigFile"
      :cancelAction="canceImportFile"
      :confirmAction="addData"
    />
  </div>
</template>

<script>
import api from "@/services"
import ExpenseConfigTable from "../Table/ExpenseConfigTable.vue"
import Pagination from "@/components/Layout/Pagination.vue"
import DialogCreateExpenseConfig from "../Dialog/DialogCreateExpenseConfig.vue"
import DialogImportExpenseConfig from "../Dialog/DialogImportExpenseConfig.vue"
import DialogListImportExpenseConfig from "../Dialog/DialogListImportExpenseConfig.vue"
export default {
  components: {
    ExpenseConfigTable,
    Pagination,
    DialogCreateExpenseConfig,
    DialogImportExpenseConfig,
    DialogListImportExpenseConfig,
  },

  data() {
    return {
      showUpdateData: false,
      showCreateData: false,
      showImportConfigFile: false,
      showListImportConfigFile: false,

      listData: [],
      loadingSearch: false,
      isFirstLoading: true,
      forcedLoading: false,
      refIntersect: 0,
      selectRef: 0,
      contactSearchQuery: "",
      firstSync: true,
      pagination: {
        total: 1,
        total_pages: 1,
        per_page: 15,
        page: 1,
      },
    }
  },
  computed: {
    showSkeletonPost() {
      return !this.isFirstLoading
    },
    currentQuery() {
      if (this.routeName === "news-search") {
        return this.$route.query.q
      }
      return ""
    },
    listDataComputed() {
      return this.listData
    },
  },
  created() {
    if (this.listData && this.listData.length) {
      this.isFirstLoading = false
    }
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.getListData()
      },
    },
    pagination: {
      deep: true,
      handler() {
        this.syncUrl()
      },
    },
    contactSearchQuery() {
      if (this.firstSync) {
        return
      }
      clearTimeout(this.refIntersect)
      this.refIntersect = setTimeout(() => {
        this.pagination = {
          ...this.pagination,
          page: 1,
        }
        // this.syncUrl()
      }, 700)
    },
    selectRef() {
      if (this.firstSync) {
        return
      }
      clearTimeout(this.refIntersect)
      this.refIntersect = setTimeout(() => {
        this.pagination = {
          ...this.pagination,
          page: 1,
        }
      }, 700)
    },
  },
  mounted() {
    this.bindUrl()
    this.getListData()
  },
  methods: {
    viewDetail(id) {
      const path = this.$route.path
      this.$router.push(`/${String(path).replace("/", "")}/${id}`)
    },
    addData() {
      this.getListData()
    },
    cancelCreateData() {
      this.showCreateData = false
    },
    canceImportFile() {
      this.showImportConfigFile = false
    },

    pagePaginationChange(_val) {
      this.pagination = {
        ...this.pagination,
        per_page: _val.per_page,
        page: _val.page,
      }
    },
    removeDataLocal(_data) {
      const index = this.listData.findIndex((o) => o.id === _data.id)
      if (index !== -1) {
        this.listData.splice(index, 1)
      }
    },
    updateDataLocal(_data) {
      const index = this.listData.findIndex((o) => o.id === _data.id)
      if (index !== -1) {
        this.listData.splice(index, 1, _data)
      }
    },
    syncUrl() {
      const query = {
        size: this.pagination.per_page,
        page: this.pagination.page,
      }
      if (this.contactSearchQuery) {
        query.search = this.contactSearchQuery
      }
      const path = this.$route.path
      this.$router.replace({ path, query }).catch(() => {})
    },
    bindUrl() {
      const query = this.$route.query
      if (query.search) {
        this.contactSearchQuery = query.search
      }
      if (+query.size) {
        this.pagination = { ...this.pagination, per_page: +query.size }
      }
      if (+query.page) {
        this.pagination = { ...this.pagination, page: +query.page }
      }
    },
    pickedDate(_val) {
      console.log(_val)
      if (!_val || !_val.value || !_val.value.startDate) {
        this.fromDate = ""
        this.toDate = ""
        this.syncUrl()
        return
      }
      this.fromDate = _val.value.startDate
      this.toDate = _val.value.endDate
      this.syncUrl()
    },
    async getListData() {
      this.loadingSearch = true
      const query = { ...this.$route.query }
      const payload = { ...query }
      const res = await api.expenseConfigurations.getListData(payload)
      this.isFirstLoading = false
      this.loadingSearch = false
      this.firstSync = false
      if (!res) {
        this.$store.commit("toast/getError", "Lỗi lấy dữ liệu")
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          console.log(res)
          return
        }
        const dataObj = res.data.data
        this.listData = dataObj.expense_configurations

        this.pagination = {
          total: dataObj.meta.pagination.total,
          total_pages: dataObj.meta.pagination.total_pages,
          per_page: dataObj.meta.pagination.per_page,
          page: dataObj.meta.pagination.current_page,
        }
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
    async updateStatus(dataId, payload) {
      this.loadingStatus = true
      const res = await api.expenseConfigurations.updateStatus(dataId, payload)
      this.loadingStatus = false
      if (!res) {
        this.$store.commit(
          "toast/getError",
          this.sysLanguage.snackbar.removeCompanyFail,
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          return
        }
        this.$store.commit("toast/getSuccess", "Thao tác thành công")

        this.getListData()
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
    resolveSubmitLocal(_post) {
      const index = this.listData.findIndex((o) => o.id === _post.id)
      if (index !== -1) {
        this.listData.splice(index, 1, _post)
      }
    },
  },
}
</script>

<style>
.container-empty-background {
  display: block;
  /* margin: auto; */
  max-width: 95%;
}
.content-slot-content {
  height: calc(100% - 44px);
  border-radius: 8px;
  overflow: hidden;
}
</style>
<style lang="scss" scoped>
.news-container {
  .filter-wr {
    height: 100px;
  }
  .table-wrapper {
    position: relative;
    height: calc(100% - 100px);
    padding-bottom: 70px;
    overflow-x: scroll;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #e6e6e600;
      border-radius: 0px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background: rgba(176, 176, 176, 0.526);
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background: rgb(96, 96, 96);
      }
    }
  }
}
</style>
