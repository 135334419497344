const repo = (axios) => {
  return {
    getListData(payload) {
      return axios
        .get(`/api/admin/expense-management/classifications`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
  }
}
export default repo
