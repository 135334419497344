<template>
  <div style="position: relative">
    <div v-if="detailData && !detailData.linked_revenue">
      <ConfigLinkedRevenueItem
        v-for="item in listData"
        :key="item.id"
        :application="item"
        :childShake="childShake"
        :flagSubmit="flagSubmit"
        @failed="failedChild"
        @collect="collectChild"
      />
      <div class="d-flex items-center justify-center group-btn">
        <v-btn
          color="primary"
          depressed
          style="border-radius: 4px"
          class="low-letter-spacing d-block font-size-14"
          :loading="loadingLinked"
          @click="triggerChildSubmit"
        >
          Lưu
        </v-btn>
      </div>
    </div>
    <div v-if="detailData && detailData.linked_revenue">
      <LinkedRevenueItem
        v-for="item in listData"
        :key="item.id"
        :application="item"
        :childShake="childShake"
        :flagSubmit="flagSubmit"
        @failed="failedChild"
        @collect="collectChild"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import ConfigLinkedRevenueItem from "../ExpenseConfig/ConfigLinkedRevenueItem.vue"
import api from "@/services"
import LinkedRevenueItem from "../ExpenseConfig/LinkedRevenueItem.vue"
export default {
  props: {
    detailData: {
      type: Object,
      default() {
        return {}
      },
    },
    getDetailData: {
      type: Function,
      default() {
        return {}
      },
    },
  },
  components: { ConfigLinkedRevenueItem, LinkedRevenueItem },
  data() {
    return {
      listData: [],
      failedChilds: [],
      childShake: 0,
      flagSubmit: false,
      loadingLinked: false,
    }
  },
  computed: {
    ...mapState({
      expenseClassifications: (state) => state.users.expenseClassifications,
    }),
  },
  watch: {
    applications: {
      deep: true,
      handler() {
        this.syncData()
      },
    },
  },
  mounted() {
    this.syncData()
  },
  methods: {
    async triggerChildSubmit() {
      this.flagSubmit = true
      this.childShake += 1
      this.loadingLinked = true
      await this.dirtyWait(500)
      this.onSubmit()
    },

    syncData() {
      if (!this.detailData.linked_revenue) {
        this.listData = this.expenseClassifications.map((o) => {
          const obj = {
            ...o,
            linked_items: [
              {
                id: "fk" + o.id,
              },
            ],
          }
          return obj
        })
        this.failedChilds = this.expenseClassifications.map((o) => o.id)
      } else {
        const listLinks = this.detailData.links || []
        const cloneClassifications = this.expenseClassifications.map((o) => {
          const obj = {
            ...o,
            link_items: listLinks.filter(
              (k) =>
                k.expense_category &&
                k.expense_category.classification_id === o.id,
            ),
          }
          return obj
        })
        this.listData = cloneClassifications
      }
    },
    failedChild(payload) {
      this.failedChilds.push(payload.id)
      this.listData.filter((o) => o.id !== payload.id)
    },
    collectChild(payload) {
      this.failedChilds = this.failedChilds.filter((o) => o !== payload.id)
      const matchedIdx = this.listData.findIndex((o) => o.id === payload.id)
      if (matchedIdx !== -1) {
        const { linked_items } = payload
        this.listData.splice(matchedIdx, 1, {
          ...this.listData[matchedIdx],
          linked_items,
        })
      }
    },
    async onSubmit() {
      if (this.failedChilds.length) {
        this.loadingLinked = false
        return
      }
      const body = {
        link_items: this.listData
          .map((o) => o.linked_items)
          .flat()
          .map((o) => ({
            expense_category_id: o.expense_category_id,
            revenue_category_id: o.revenue_category_id,
          })),
      }
      this.loadingLinked = true
      const res = await api.expenseConfigurations.linkRevenue(
        this.detailData.id,
        body,
      )
      this.loadingLinked = false
      if (!res) {
        this.$store.commit(
          "toast/getError",
          res.data.message || "Thao tác thất bại",
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit(
            "toast/getError",
            res.data.message || "Thao tác thất bại",
          )
          return
        }
        this.$store.commit(
          "toast/getSuccess",
          "Liên kết thu chi thành công, bạn có thể tạo định mức chi phí, doanh thu.",
        )
        this.getDetailData()
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.group-btn {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 2;
}
</style>

<!-- 

    <v-list-group :value="true" prepend-icon="mdi-account-circle">
      <template v-slot:activator>
        <v-list-item-title>Users</v-list-item-title>
      </template>
      <div>A1</div>
      <div>A2</div>
    </v-list-group>

 -->
