const repo = (axios) => {
  const getRequestHandler = (_url, payload) => {
    return axios
      .get(`${_url}`, {
        params: { ...payload },
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  }
  return {
    getListVehicleTypes(payload) {
      return axios
        .get(`/api/common-type/vehicle-types`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getListCustomerCompany(payload) {
      return axios
        .get(`/api/admin/customer-management/companies`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getListGates(payload) {
      return axios
        .get(`/api/common-type/gates`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getListUnits(payload) {
      return axios
        .get(`/api/common-type/units`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getListRevenueTypes(payload) {
      return axios
        .get(`/api/common-type/revenue-types`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    // KHO
    // Danh sách loại hình xuất nhập khẩu
    getListEximTypes(payload) {
      return getRequestHandler(`/api/common-type/exim-types`, payload)
    },
    // Danh sách loại hình sang tải
    getListTranshipmentForms(payload) {
      return getRequestHandler(`/api/common-type/transhipment-forms`, payload)
    },
    // Danh sách quy cách đóng gói
    getListWarehousePackagingSpecifications(payload) {
      return getRequestHandler(
        `/api/common-type/wh-packaging-specifications`,
        payload,
      )
    },
    // Danh sách loại hàng
    getListGoodTypes(payload) {
      return getRequestHandler(`/api/common-type/goods-types`, payload)
    },
    // Danh sách điều kiện của cấu hình giá kho
    getListConditionTypes(payload) {
      return getRequestHandler(`/api/common-type/condition-types`, payload)
    },
    // Danh sách điều kiện ghi nhận
    getListVisibleClassifications(payload) {
      return getRequestHandler(
        `/api/common-type/visible-classifications`,
        payload,
      )
    },
    // Danh sách loại chi phí phía khách hàng
    getListCustomerFeeClassifications(payload) {
      return getRequestHandler(
        `/api/common-type/customer-fee-classifications`,
        payload,
      )
    },
    // Danh sách đơn vị tính cho doanh thu kho
    getListWarehouseRevenueUnits(payload) {
      return getRequestHandler(`/api/common-type/wh-revenue-units`, payload)
    },
  }
}
export default repo
