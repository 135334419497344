<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="920"
    style="border-radius: 8px"
  >
    <v-card class="create-position-dialog">
      <v-card-title class="primary px-4">
        <div class="d-flex align-center">
          <v-btn color="white" icon @click="cancelAction"
            ><v-icon size="20px">mdi-close</v-icon></v-btn
          >
          <span
            class="white--text dialog-title"
            style="font-size: 16px !important; font-weight: 400"
            >Thêm cấu hình</span
          >
        </div>
      </v-card-title>
      <div class="dialog-body-content">
        <div class="left-side scroll-y-mod_big">
          <v-row>
            <v-col cols="6" class="py-0">
              <div class="input-block pb-2">
                <label
                  class="input-form-label font-weight-500 black--text pb-2"
                >
                  Tên cấu hình
                  <span class="semantic_error--text">*</span></label
                >
                <input
                  v-model="name"
                  type="text"
                  class="input input-form-border neutral_color_sub3 bg-white"
                  :class="errorObj.name ? 'error-border' : ''"
                  placeholder="Tên cấu hình"
                />
                <p
                  v-if="errorObj.name"
                  class="semantic_error--text error-msg-small mb-0"
                >
                  {{ errorObj.name }}
                </p>
              </div>
            </v-col>
            <v-col cols="6" class="py-0">
              <div class="input-block read-only pb-2">
                <label class="input-form-label black--text pb-2">
                  Cửa khẩu <span class="semantic_error--text">*</span>
                </label>
                <div
                  class="info-input px-0 neutral_color_sub3--text"
                  :class="errorObj.application ? 'error-border' : ''"
                >
                  <select-auto
                    :key="gate_id"
                    :placeholder="'-- Chọn cửa khẩu --'"
                    :onChange="onChangeGate"
                    :default-value="+gate_id"
                    :options="listGates"
                    :clearAble="false"
                    :custom-class="'v-autocomplete-no-border w-100 v-autocomplete-size-16'"
                  />
                </div>
                <p
                  v-if="errorObj.application"
                  class="semantic_error--text error-msg-small mb-0"
                >
                  {{ errorObj.application }}
                </p>
              </div>
            </v-col>
            <v-col cols="6" class="py-0">
              <div class="input-block read-only pb-2">
                <label class="input-form-label black--text pb-2">
                  Loại hình <span class="semantic_error--text">*</span>
                </label>
                <div
                  class="info-input px-0 neutral_color_sub3--text"
                  :class="errorObj.application ? 'error-border' : ''"
                >
                  <select-auto
                    :key="order_type_id"
                    :placeholder="'-- Chọn loại hình --'"
                    :onChange="onChangeOrderType"
                    :default-value="+order_type_id"
                    :customNoDataMessage="`Vui lòng chọn 'Cửa khẩu' trước`"
                    :options="listOrderTypesByGate"
                    :clearAble="false"
                    :custom-class="'v-autocomplete-no-border w-100 v-autocomplete-size-16'"
                  />
                </div>
                <p
                  v-if="errorObj.application"
                  class="semantic_error--text error-msg-small mb-0"
                >
                  {{ errorObj.application }}
                </p>
              </div>
            </v-col>
            <v-col cols="6" class="py-0">
              <div class="input-block read-only pb-2">
                <label class="input-form-label black--text pb-2">
                  Ngày áp dụng <span class="semantic_error--text">*</span>
                </label>
                <div
                  class="info-input px-0 neutral_color_sub3--text"
                  :class="errorObj.application ? 'error-border' : ''"
                >
                  <DatePicker
                    v-model="apply_date"
                    type="date"
                    format="DD-MM-YYYY"
                    value-type="timestamp"
                    class="w-100 single-date-v2 single-date-blank"
                    :placeholder="'-- Chọn ngày --'"
                    :disabledDate="notBeforeToday"
                  ></DatePicker>
                </div>
              </div>
            </v-col>
            <v-col cols="6" class="py-0">
              <div class="input-block read-only pb-2">
                <label class="input-form-label black--text pb-2">
                  Công ty áp dụng <span class="semantic_error--text">*</span>
                </label>
                <div
                  class="info-input px-0 neutral_color_sub3--text"
                  :class="errorObj.company_id ? 'error-border' : ''"
                >
                  <select-auto
                    :key="company_id"
                    :placeholder="'-- Chọn công ty --'"
                    :onChange="onChangeCompany"
                    :default-value="+company_id"
                    :options="listCustomerCompanies"
                    :clearAble="false"
                    :custom-class="'v-autocomplete-no-border w-100 v-autocomplete-size-16'"
                  />
                </div>
                <p
                  v-if="errorObj.company_id"
                  class="semantic_error--text error-msg-small mb-0"
                >
                  {{ errorObj.company_id }}
                </p>
              </div>
            </v-col>
            <v-col cols="12" class="py-0">
              <div class="input-block read-only pb-2">
                <label class="input-form-label black--text pb-2">
                  Loại xe áp dụng <span class="semantic_error--text">*</span>
                </label>
                <div
                  class="info-input px-0 py-0 neutral_color_sub3--text"
                  :class="errorObj.application ? 'error-border' : ''"
                  style="height: auto; min-height: 48px"
                >
                  <select-auto
                    v-model="vehicles"
                    :key="+dialog"
                    :placeholder="'-- Chọn loại xe --'"
                    :options="listVehicleTypes"
                    clearAble
                    multiple
                    chips
                    deletableChips
                    hideSelected
                    :custom-class="'v-autocomplete-no-border w-100 v-autocomplete-size-16 v-select__selections-small v-select__selections-gap-y-1'"
                    :onChange="updateVehicles"
                  />
                </div>
                <p
                  v-if="errorObj.application"
                  class="semantic_error--text error-msg-small mb-0"
                >
                  {{ errorObj.application }}
                </p>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <!-- ACTIONS -->
      <div class="news_block_bg pb-2 px-3 group-btn-add-user mt-1 d-flex">
        <v-btn
          :disabled="loadingBtn"
          :color="'neutral_color_sub4 neutral_color_sub2--text'"
          class="rounded-lg v-btn-confirm ml-auto mr-2"
          @click="cancelAction"
          depressed
        >
          {{ sysLanguage.button.cancel }}
        </v-btn>
        <v-btn
          :color="'primary'"
          :loading="loadingBtn"
          class="rounded-lg v-btn-confirm"
          :disabled="!ableToCreate"
          @click="onSubmit"
          depressed
        >
          {{ sysLanguage.button.save }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import handleFiles from "@/mixins/handleFiles.js"
import api from "@/services"
import { mapState } from "vuex"
import SelectAuto from "../Filter/SelectAuto.vue"
import DatePicker from "vue2-datepicker"
import { formatDateYMD } from "@/helpers/dateFormater.js"
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    cancelAction: {
      type: Function,
      default() {},
    },
    confirmAction: {
      type: Function,
      default() {},
    },
  },
  components: { SelectAuto, DatePicker },
  mixins: [handleFiles],
  data() {
    return {
      dialog: false,
      loadingBtn: false,
      submitClicked: false,
      gate_id: null,
      order_type_id: null,
      company_id: null,
      vehicles: [],
      apply_date: "",
      name: "",
    }
  },
  computed: {
    ...mapState({
      listUsers: (state) => state.users.listUsers,
      applications: (state) => state.users.applications,
      listVehicleTypes: (state) => state.listVehicleTypes,
      listCustomerCompanies: (state) => state.listCustomerCompanies,
      listGates: (state) => state.listGates,
    }),
    ableToCreate() {
      return !!this.name && !!String(this.name).trim()
      // return true
    },
    listOrderTypesByGate() {
      const rslt = this.listGates.find((o) => o.id === this.gate_id)
      return rslt ? rslt.order_types || [] : []
    },
    errorObjUnder() {
      const obj = {}
      if (!this.name || !String(this.name).trim()) {
        obj.name = "Không được để trống tên công ty"
      }
      if (!this.apply_date) {
        obj.apply_date = "Không được để trống ngày áp dụng"
      }
      if (!this.order_type_id) {
        obj.order_type_id = "Không được để trống cửa khẩu"
      }
      if (!this.company_id) {
        obj.company_id = "Không được để trống công ty khách"
      }
      if (!this.vehicles || !this.vehicles.length) {
        obj.vehicles = "Không được để trống xe"
      }

      return obj
    },
    errorObj() {
      return this.submitClicked ? this.errorObjUnder : {}
    },
  },
  watch: {
    isVisible() {
      this.resetData()
      this.dialog = this.isVisible
    },
    code() {
      if (this.code.length > this.limitSizeCodeName) {
        this.code = this.code.slice(0, this.limitSizeCodeName)
      }
    },
  },
  mounted() {},
  methods: {
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    resetData() {
      this.submitClicked = false

      this.gate_id = null
      this.order_type_id = null
      this.company_id = null
      this.name = ""
      this.apply_date = ""
      this.vehicles = []
    },
    updateVehicles(_val) {
      this.vehicles = _val || []
    },
    onChangeCompany(_val) {
      this.company_id = _val
    },

    onChangeGate(_val) {
      this.gate_id = _val
    },
    onChangeOrderType(_val) {
      this.order_type_id = _val
    },
    // USER HANDLER
    async onSubmit() {
      this.submitClicked = true
      if (Object.keys(this.errorObjUnder).length) {
        return
      }
      const body = {
        name: this.name,
        apply_date: formatDateYMD(this.apply_date),
        order_type_id: this.order_type_id,
        company_id: this.company_id,
        vehicle_type_ids: this.vehicles,
      }
      this.loadingBtn = true
      const res = await api.expenseConfigurations.create(body)
      this.loadingBtn = false
      if (!res) {
        this.$store.commit(
          "toast/getError",
          res.data.message || "Thao tác thất bại",
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit(
            "toast/getError",
            res.data.message || "Thao tác thất bại",
          )
          return
        }
        this.$store.commit("toast/getSuccess", "Thêm cấu hình thành công")
        this.confirmAction()
        this.cancelAction()
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.create-position-dialog {
  border-radius: 8px;
  .dialog-title {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 28px;
  }
  .dialog-body-content {
    display: flex;
    padding: 0 24px;
    max-height: calc(90vh - 70px);
    .left-side {
      width: 100%;
      padding: 20px 8px 40px;
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      .input-form-border {
        border-radius: 8px;
        border: 1px solid;
        height: 48px;
        padding: 12px 8px;
        font-weight: 400;
        font-size: 16px;
        &.v-texarea-normalize {
          height: auto;
        }
      }
      .input-block {
        display: flex;
        flex-flow: column;
        &.flex-input {
          flex-flow: row;
        }
        .input {
          &:focus {
            outline: none;
          }
        }
        .input-form-label {
          letter-spacing: 0.0015em;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
    .info-input {
      display: flex;
      align-items: center;
      border: 1px solid;
      border-radius: 8px;
      height: 48px;
      padding: 10px 12px;
    }
  }
}
</style>
<style lang="scss">
.create-position-dialog {
  border-radius: 8px !important;
}
.single-date-blank {
  border: none;
  .mx-input-wrapper {
    width: 100%;
    height: 100%;
  }
  .mx-input {
    border: none !important;
    box-shadow: none !important;
    &::placeholder {
      font-size: 16px;
    }
  }
}
</style>
<style lang="scss" scoped>
.single-date-v2 {
  border: none;
  .mx-input-wrapper {
    width: 100%;
    height: 100%;
  }
  .mx-input {
    border: none !important;
  }
}
</style>
