var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2 custom-bg mx-2 box-shadow--mod-lighter"},[_c('v-list-group',{attrs:{"value":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(_vm.application.name))])]},proxy:true}])},[_c('div',{staticClass:"px-1 py-2"},[_vm._l((_vm.listLinked),function(item){return _c('v-row',{key:item.id,staticClass:"my-0 mx-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"input-block read-only pb-2"},[_c('label',{staticClass:"input-form-label black--text pb-2"},[_vm._v(" Chi phí "),_c('span',{staticClass:"semantic_error--text"},[_vm._v("*")])]),_c('div',{staticClass:"info-input px-0 neutral_color_sub3--text",class:_vm.flagSubmit && !item.expense_category_id ? ' error-border' : ''},[_c('select-auto',{attrs:{"placeholder":'-- Chọn loại hình --',"onChange":function (_val) {
                    _vm.onChangeExpense(_val, item)
                  },"default-value":+item.expense_category_id,"customNoDataMessage":"Không có danh mục để chọn. Liên hệ IT để đượ trợ giúp","options":_vm.application.expense_categories,"clearAble":false,"custom-class":'v-autocomplete-no-border w-100 v-autocomplete-size-14'}})],1)])]),_c('v-col',{staticClass:"py-0 d-flex items-center",attrs:{"cols":"6"}},[_c('div',{staticClass:"input-block read-only pb-2 w-100"},[_c('label',{staticClass:"input-form-label black--text pb-2"},[_vm._v(" Doanh thu "),_c('span',{staticClass:"semantic_error--text"},[_vm._v("*")])]),_c('div',{staticClass:"info-input px-0 neutral_color_sub3--text",class:_vm.flagSubmit && !item.revenue_category_id ? ' error-border' : ''},[_c('select-auto',{key:item.revenue_category_id,attrs:{"placeholder":'-- Chọn loại hình --',"onChange":function (_val) {
                    _vm.onChangeRevenue(_val, item)
                  },"default-value":+item.revenue_category_id,"customNoDataMessage":"Không có danh mục để chọn. Liên hệ IT để đượ trợ giúp","options":_vm.application.revenue_categories,"clearAble":false,"custom-class":"v-autocomplete-no-border w-100 v-autocomplete-size-14"}})],1)]),(_vm.listLinked.length > 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pointer text-red-500",staticStyle:{"min-width":"24px","height":"24px","align-self":"flex-end","margin-bottom":"12px"},attrs:{"size":"24px"},on:{"click":function($event){return _vm.removeItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-trash-can-outline ")])]}}],null,true)},[_c('span',[_vm._v("Xoá")])]):_vm._e()],1)],1)}),_c('v-btn',{staticClass:"low-letter-spacing ml-auto mr-3 d-block font-size-14",staticStyle:{"border-radius":"4px"},attrs:{"color":"primary","depressed":""},on:{"click":_vm.addNewRow}},[_vm._v(" THÊM ĐẦU MỤC ")])],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }