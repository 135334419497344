<template>
  <div class="mb-2 custom-bg mx-2 box-shadow--mod-lighter">
    <v-list-group :value="true" class="">
      <template v-slot:activator>
        <v-list-item-title>{{ application.name }}</v-list-item-title>
      </template>
      <div class="px-1 py-2">
        <table class="table-container main-page-table w-100 white users-table">
          <thead class="text-neutral-900 fw-500">
            <td class="text-center" style="width: 50%">Chi phí</td>
            <td class="text-center" style="width: 50%">Doanh thu</td>
          </thead>
          <!-- <tbody class="white">
            <tr v-for="data in myLinkedItems" :key="data.id" class="">
              <td>
                <div class="d-flex gap-2 justify-center hover-show-copy w-100">
                  <p class="mb-0 limit-line-1 text-left pl-2">
                    {{
                      data.expense_category ? data.expense_category.name : "-/-"
                    }}
                  </p>
                </div>
              </td>
              <td class="text-center">
                <p class="mb-0 w-100 text-center">
                  {{
                    data.revenue_category ? data.revenue_category.name : "-/-"
                  }}
                </p>
              </td>
            </tr>
          </tbody> -->

          <tbody
            v-for="(item, idx) in groupBySameExpenseCategoryComputed"
            :key="item.id + 'BySameExpenseCategory' + idx"
            class="white"
          >
            <tr v-for="(data, idx) in item.items" :key="data.id" class="">
              <td
                v-if="idx === 0"
                :rowspan="item.items.length"
                class="border-right"
              >
                <div class="d-flex gap-2 justify-center hover-show-copy w-100">
                  <p class="mb-0 limit-line-1 text-left pl-2">
                    {{
                      data.expense_category ? data.expense_category.name : "-/-"
                    }}
                  </p>
                </div>
              </td>
              <td class="text-center">
                <p class="mb-0 w-100 text-center">
                  {{
                    data.revenue_category ? data.revenue_category.name : "-/-"
                  }}
                </p>
              </td>
            </tr>
          </tbody>
          <tbody
            v-for="(item, idx) in groupBySameRevenueCategoryComputed"
            :key="item.id + 'BySameRevenueCategory' + idx"
            class="white"
          >
            <tr v-for="(data, idx) in item.items" :key="data.id" class="">
              <td class="border-right">
                <div class="d-flex gap-2 justify-center hover-show-copy w-100">
                  <p class="mb-0 limit-line-1 text-left pl-2">
                    {{
                      data.expense_category ? data.expense_category.name : "-/-"
                    }}
                  </p>
                </div>
              </td>
              <td
                v-if="idx === 0"
                :rowspan="item.items.length"
                class="text-center"
              >
                <p class="mb-0 w-100 text-center">
                  {{
                    data.revenue_category ? data.revenue_category.name : "-/-"
                  }}
                </p>
              </td>
            </tr>
          </tbody>
          <tbody
            v-for="(item, idx) in restLinkedItemsComputed"
            :key="idx"
            class="white"
          >
            <tr v-for="data in item.items" :key="data.id" class="">
              <td class="border-right">
                <div class="d-flex gap-2 justify-center hover-show-copy w-100">
                  <p class="mb-0 limit-line-1 text-left pl-2">
                    {{
                      data.expense_category ? data.expense_category.name : "-/-"
                    }}
                  </p>
                </div>
              </td>
              <td class="text-center">
                <p class="mb-0 w-100 text-center">
                  {{
                    data.revenue_category ? data.revenue_category.name : "-/-"
                  }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-list-group>
  </div>
</template>

<script>
// import CopyButton from "../Layout/CopyButton.vue"
export default {
  components: {},
  props: {
    application: {
      type: Object,
      default() {
        return {}
      },
    },
    childShake: {
      type: Number,
      default: 0,
    },
    flagSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  watch: {
    childShake() {
      this.submitData()
    },
  },
  computed: {
    myLinkedItems() {
      const nArray = [...this.application.link_items]
      const arr = nArray.sort(
        (a, b) => a.expense_category.id - b.expense_category.id,
      )
      return arr
    },
    groupBySameExpenseCategoryComputed() {
      try {
        // group all same expense_category.id first;
        const availableExpenseCategory = [
          ...new Set(this.myLinkedItems.map((o) => o.expense_category.id)),
        ]

        const groupBySameExpenseCategory = availableExpenseCategory
          .map((o) => {
            const obj = {
              id: o,
              items: this.myLinkedItems.filter(
                (k) => k.expense_category.id === o,
              ),
            }
            return obj
          })
          .filter((o) => o.items.length > 1)

        return groupBySameExpenseCategory
      } catch (error) {
        return []
      }
    },
    usedByGroupExpense() {
      return this.groupBySameExpenseCategoryComputed
        .map((o) => o.items.map((k) => k.id))
        .flat()
    },
    groupBySameRevenueCategoryComputed() {
      try {
        const groupBySameExpenseCategory =
          this.groupBySameExpenseCategoryComputed
        const listRevenueIdsUsed = groupBySameExpenseCategory
          .map((o) => o.items.map((k) => k.id))
          .flat()
        // group all same revenue_category.id that left;
        const availableRevenueCategory = [
          ...new Set(
            this.myLinkedItems
              .filter(
                (o) => !listRevenueIdsUsed.includes(o.revenue_category.id),
              )
              .map((o) => o.revenue_category.id),
          ),
        ]
        const groupBySameRevenueCategory = availableRevenueCategory
          .map((o) => {
            const obj = {
              id: o,
              items: this.myLinkedItems
                .filter((o) => !this.usedByGroupExpense.includes(o.id))
                .filter((k) => k.revenue_category.id === o),
            }
            return obj
          })
          .filter((o) => o.items.length > 1)

        return groupBySameRevenueCategory
      } catch (error) {
        return []
      }
    },
    usedByGroupRevenue() {
      return this.groupBySameRevenueCategoryComputed
        .map((o) => o.items.map((k) => k.id))
        .flat()
    },
    restLinkedItemsComputed() {
      try {
        // get all used ids and filter

        const restItems = this.myLinkedItems.filter(
          (o) =>
            ![...this.usedByGroupRevenue, ...this.usedByGroupExpense].includes(
              o.id,
            ),
        )
        return [
          {
            id: "rest",
            items: restItems,
          },
        ]
      } catch (error) {
        return []
      }
    },
  },
  mounted() {
    console.log(this.myLinkedItemsComputed)
  },
  methods: {},
}
</script>

<style lang="scss">
.custom-bg {
  // .v-list-group--active {
  //   background-color: #000 !important;
  // }
  border: 1px solid #ebebeb;
  border-radius: 8px;
  overflow: hidden;
  .v-list-item {
    background-color: #0d7042 !important;
    border-radius: 8px 8px 0 0;
    color: #fff !important;
    .v-icon {
      color: #fff !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.info-input {
  display: flex;
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  height: 40px;
  padding: 4px 12px;
}
.table-container {
  th,
  td {
    border-bottom: 1px solid #b8b8b8;

    &:first-child {
    }
  }
  .border-right {
    border-right: 1px solid #b8b8b8;
  }
  .border-left {
    border-left: 1px solid #b8b8b8;
  }
}
</style>
