<template>
  <div
    class="m-0 p-0 pt-1 user-feed-rowflex-column align-start white content-slot-container scroll-y-mod_big"
    style="overflow-y: scroll"
  >
    <div class="content-slot-header py-2 px-3 w-100 justify-start align-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            class="mb-0 mr-1"
            color="neutral"
            @click="fallBack"
          >
            <v-icon> mdi-arrow-left </v-icon>
          </v-btn>
        </template>
        <span>Quay lại</span>
      </v-tooltip>
      Cấu hình định mức
    </div>
    <div :key="keyUpdate" cols="12" class="py-0 px-3">
      <div class="mx-auto w-100 content-wrap-relative">
        <v-btn
          class="mx-auto my-5 d-block box-shadow--mod white btn-load-absolute"
          fab
          light
          small
          loading
          v-if="isFirstLoading || forcedLoading"
        >
          <v-icon dark> mdi-minus </v-icon>
        </v-btn>
        <NewsLoader v-if="showSkeletonPost" />
        <div v-if="!showSkeletonPost" class="detail-content">
          <div class="py-2 mt-4">
            <v-tabs
              v-model="tab"
              background-color="transparent"
              class="mb-4 neutral_color_sub5--text justify-center custom-tabs"
            >
              <v-tab class="v-tab-normalize px-5"> Thông tin chung</v-tab>
              <v-tab class="v-tab-normalize px-5"> Liên kết thu chi </v-tab>
              <v-tab class="v-tab-normalize px-5"> Định mức chi phí </v-tab>
              <v-tab class="v-tab-normalize px-5"> Báo giá doanh thu </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <ExpenseConfigDetailGeneralInfoContainer
                  :detailData="detailData"
                />
              </v-tab-item>
              <v-tab-item>
                <div class="">
                  <ExpenseConfigLinkedRevenueContainer
                    :detailData="detailData"
                    :getDetailData="getDetailData"
                  />
                </div>
              </v-tab-item>

              <v-tab-item>
                <div class="">
                  <div v-if="!detailData.linked_revenue">
                    <h2 class="text-center my-10">
                      Định mức chưa được liên kết thu chi.
                      <br />
                      Vui lòng liên kết thu chi trước khi tiếp tục
                    </h2>
                    <v-btn
                      color="primary"
                      depressed
                      style="border-radius: 4px"
                      class="low-letter-spacing d-block font-size-14 mx-auto"
                      @click="tab = 1"
                    >
                      Liên kết ngay
                    </v-btn>
                  </div>
                  <ExpenseDefaultPricingTable
                    v-if="
                      detailData.linked_revenue &&
                      (!detailData.expense_items ||
                        !detailData.expense_items.length)
                    "
                    :detailData="detailData"
                    :list-data="defaultExpenseItems"
                    :isFirstLoading="isFirstLoading"
                    :loadingTable="loadingSearch"
                    :getDetailData="getDetailData"
                  />
                  <ExpenseDefaultPricingTableReadOnly
                    v-if="
                      detailData.linked_revenue &&
                      Array.isArray(detailData.expense_items) &&
                      detailData.expense_items.length
                    "
                    :detailData="detailData"
                    :list-data="defaultExpenseItems"
                    :isFirstLoading="isFirstLoading"
                    :loadingTable="loadingSearch"
                    :getDetailData="getDetailData"
                  />
                </div>
              </v-tab-item>

              <v-tab-item>
                <div class="">
                  <div v-if="!detailData.linked_revenue">
                    <h2 class="text-center my-10">
                      Định mức chưa được liên kết thu chi.
                      <br />
                      Vui lòng liên kết thu chi trước khi tiếp tục
                    </h2>
                    <v-btn
                      color="primary"
                      depressed
                      style="border-radius: 4px"
                      class="low-letter-spacing d-block font-size-14 mx-auto"
                      @click="tab = 1"
                    >
                      Liên kết ngay
                    </v-btn>
                  </div>
                  <RevenueDefaultPricingTable
                    v-if="
                      detailData.linked_revenue &&
                      (!detailData.revenue_items ||
                        !detailData.revenue_items.length)
                    "
                    :detailData="detailData"
                    :list-data="defaultRevenueItems"
                    :isFirstLoading="isFirstLoading"
                    :loadingTable="loadingSearch"
                    :getDetailData="getDetailData"
                  />
                  <RevenueDefaultPricingTableReadOnly
                    v-if="
                      detailData.linked_revenue &&
                      Array.isArray(detailData.revenue_items) &&
                      detailData.revenue_items.length
                    "
                    :detailData="detailData"
                    :list-data="defaultExpenseItems"
                    :isFirstLoading="isFirstLoading"
                    :loadingTable="loadingSearch"
                    :getDetailData="getDetailData"
                  />
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsLoader from "@/components/Loader/NewsLoader"

import { formatDateDMYH } from "@/helpers/dateFormater.js"
import objHandlerMixins from "@/mixins/objHandlerMixins.js"
import { mapState } from "vuex"
import api from "@/services"
import ExpenseConfigDetailGeneralInfoContainer from "./ExpenseConfigDetailGeneralInfoContainer.vue"
import ExpenseConfigLinkedRevenueContainer from "./ExpenseConfigLinkedRevenueContainer.vue"
import ExpenseDefaultPricingTable from "../Table/ExpenseDefaultPricingTable.vue"
import ExpenseDefaultPricingTableReadOnly from "../Table/ExpenseDefaultPricingTableReadOnly.vue"
import RevenueDefaultPricingTable from "../Table/RevenueDefaultPricingTable.vue"
import RevenueDefaultPricingTableReadOnly from "../Table/RevenueDefaultPricingTableReadOnly.vue"
export default {
  components: {
    NewsLoader,
    ExpenseConfigDetailGeneralInfoContainer,
    ExpenseConfigLinkedRevenueContainer,
    ExpenseDefaultPricingTable,
    ExpenseDefaultPricingTableReadOnly,
    RevenueDefaultPricingTable,
    RevenueDefaultPricingTableReadOnly,
  },
  mixins: [objHandlerMixins],
  data() {
    return {
      tab: null,
      submitClicked: false,
      loadingSearch: false,
      loadingBtn: false,
      isFirstLoading: true,
      detailData: {},
      defaultExpenseItems: [],
      defaultRevenueItems: [],
      forcedLoading: false,
      keyUpdate: 1,
      //
    }
  },
  computed: {
    ...mapState({}),

    showSkeletonPost() {
      return (
        this.isFirstLoading && (!this.detailComputed || !this.detailComputed.id)
      )
    },
  },
  watch: {
    $route() {
      this.getDetailData()
    },
    detailData: {
      deep: true,
      handler() {
        this.getDefaultExpenseItems()
        this.getDefaultRevenueItems()
      },
    },
  },
  mounted() {
    this.getDetailData()
  },
  methods: {
    syncUrl() {
      const query = {
        size: this.pagination.per_page,
        page: this.pagination.page,
      }
      this.getListUsers(query)
    },

    formatDateDMYH,

    fallBack() {
      const prvRoute = ""
      this.$router.push(prvRoute || "/expense-config")
      // this.$router.go(-1)
    },

    async getDetailData(isSearch = false) {
      const payload = {}
      const id = this.$route.params.id
      if (isSearch) {
        this.loadingSearch = true
      }
      const res = await api.expenseConfigurations.getDetailData(id, payload)
      this.isFirstLoading = false

      this.loadingSearch = false
      if (!res) {
        this.$store.commit("toast/getError", "Lỗi lấy chi tiết cấu hình")
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          console.log(res)
          return
        }
        const dataObj = res.data.data
        this.detailData = dataObj.expense
        this.keyUpdate += 1
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
    // async getDefaultExpenseItems() {
    //   const payload = {}
    //   const id = this.$route.params.id

    //   const res = await api.expenseConfigurations.getDefaultExpenseItems(
    //     id,
    //     payload,
    //   )
    //   this.isFirstLoading = false

    //   this.loadingSearch = false
    //   if (!res) {
    //     this.$store.commit("toast/getError", "Lỗi lấy chi tiết cấu hình")
    //     return
    //   }
    //   try {
    //     if (res.status && res.status >= 400) {
    //       this.$store.commit("toast/getError", res.data.message)

    //       return
    //     }
    //     console.log(res)
    //     const dataObj = res.data.data
    //     this.defaultExpenseItems = dataObj.items
    //   } catch (error) {
    //     this.$store.commit("toast/getError", `${error}`)
    //   }
    // },
    getDefaultExpenseItems() {
      this.defaultExpenseItems = this.detailData.default_expense_items
    },
    getDefaultRevenueItems() {
      this.defaultRevenueItems = this.detailData.default_revenue_items
    },
  },
}
</script>

<style>
.container-empty-background {
  display: block;
  /* margin: auto; */
  max-width: 95%;
}
</style>

<style lang="scss">
.custom-tabs {
  width: fit-content !important;
  margin: auto;
  background: #0d7042 !important;
  padding: 4px;
  border-radius: 4px;
  .v-tabs-bar {
    height: 36px !important;
  }
  .v-tabs-bar__content {
    justify-content: center;
  }
  .v-tab {
    border-radius: 4px;
    color: #fff !important;
    &.v-tab--active {
      color: #2d2d2d !important;
      background: #fff;
    }
  }
  .v-tabs-slider-wrapper {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
.detail-content {
  border-radius: 8px;
  position: relative;
  .table-wrapper {
    position: relative;
    /* height: calc(100% - 100px); */
    max-height: calc(100vh - 200px);
    padding-bottom: 70px;
    overflow-x: scroll;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #e6e6e600;
      border-radius: 0px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background: rgba(176, 176, 176, 0.526);
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background: rgb(96, 96, 96);
      }
    }
  }
}

.each-top-info {
  padding: 0 20px;
  .info-label {
    font-size: 16px;
    line-height: 28px;
  }
  .info-input {
    display: flex;
    align-items: center;
    border: 1px solid;
    border-radius: 8px;
    height: 48px;
    padding: 10px 12px;
  }
}
.detail-table-title {
  border-bottom: 1px solid #f2f2f2;
}
</style>
