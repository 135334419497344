<template>
  <div style="background-color: #f2f8fa">
    <v-row v-if="detailData" class="m-0 p-0 pt-1 news-feed-row align-start">
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Tên cấu hình</p>
          <p class="text-neutral-800 fw-500">{{ detailData.name }}</p>
        </div>
      </v-col>
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Cửa khẩu</p>
          <p class="text-neutral-800 fw-500">
            {{
              detailData.order_type && detailData.order_type.gate
                ? detailData.order_type.gate.name
                : "-/-"
            }}
          </p>
        </div>
      </v-col>
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Loại hình</p>
          <p class="text-neutral-800 fw-500">
            {{ detailData.order_type ? detailData.order_type.name : "-/-" }}
          </p>
        </div>
      </v-col>
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Ngày áp dụng</p>
          <p class="text-neutral-800 fw-500">
            {{ formatDateDMY(detailData.apply_date) }}
          </p>
        </div>
      </v-col>
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Công ty áp dụng</p>
          <p class="text-neutral-800 fw-500">
            {{ detailData.company ? detailData.company.name : "-/-" }}
          </p>
        </div>
      </v-col>
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Loại xe áp dụng</p>
          <p class="text-neutral-800 fw-500">
            {{
              Array.isArray(detailData.vehicle_types)
                ? detailData.vehicle_types.map((o) => o.name).join(", ")
                : "-/-"
            }}
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatDateDMY } from "@/helpers/dateFormater.js"
export default {
  props: {
    detailData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    formatDateDMY,
  },
}
</script>

<style></style>
