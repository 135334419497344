<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="920"
    style="border-radius: 8px"
  >
    <v-card class="create-position-dialog">
      <v-card-title class="px-4 border-b">
        <div class="d-flex align-center w-100">
          <span
            class="white dialog-title"
            style="font-size: 20px !important; font-weight: 700"
            >Nhập file</span
          >
          <v-btn color="neutral" class="ml-auto" icon @click="cancelAction"
            ><v-icon size="20px">mdi-close</v-icon></v-btn
          >
        </div>
      </v-card-title>
      <div class="dialog-body-content">
        <div class="left-side scroll-y-mod_big">
          <div class="d-flex gap-2">
            <p class="text-red-500 fw-500" style="min-width: 60px">Chú ý:</p>
            <div>
              <p class="mb-1">
                Tải xuống mẫu file nhập:
                <a
                  class="text-info-500"
                  href="#"
                  target="_blank"
                  @click.prevent="openExample"
                  >tại đây</a
                >
              </p>
              <p class="mb-1">
                Chỉ hỗ trợ định dạng
                <span class="font-bold text-success-500">xlsx</span>
              </p>
              <p class="mb-1">
                Dung lượng file tối đa <b>5MB</b> và <b>20.000</b> dòng
              </p>
              <p class="mb-1">
                Mỗi file nhập là 1 cấu hình. Nếu file có dữ liệu lỗi thì bạn cần
                sửa lại file và nhập lại
              </p>
            </div>
          </div>
          <div class="list-files">
            <div>
              <p>Tệp đính kèm <span class="text-error-500">*</span></p>
              <div class="file-shown px-3 py-5 pointer" @click="addFile">
                <v-icon size="32px" class="d-block mx-auto text-center mb-3"
                  >mdi-paperclip</v-icon
                >
                <p class="text-center my-0">Nhấn vào đây để tải lên</p>
              </div>
            </div>
            <div
              class="each-file align-center py-2"
              v-for="(file, idx) in fileClipBoard"
              :key="idx"
            >
              <img
                :src="file.extension ? getFileIcon(file.extension) : ''"
                height="28px"
                :alt="file.name"
              />
              <span
                class="semantic_info--text"
                target="_blank"
                :title="file.original_name"
                download=""
              >
                {{
                  file.original_name
                    ? formatFileNameLocal(file.original_name)
                    : ""
                }}
              </span>
              <v-btn
                class="ml-auto"
                color="neutral"
                icon
                @click="removeFile(idx)"
                ><v-icon size="20px">mdi-trash-can-outline</v-icon></v-btn
              >
            </div>
          </div>
        </div>
      </div>
      <!-- ACTIONS -->
      <div
        class="news_block_bg py-2 gap-2 justify-center px-3 group-btn-add-user mt-1 d-flex"
      >
        <v-btn
          :disabled="loadingBtn"
          :color="'neutral_color_sub4 neutral_color_sub2--text'"
          class="rounded-lg v-btn-confirm"
          @click="cancelAction"
          depressed
        >
          Quay lại
        </v-btn>
        <v-btn
          :color="'primary'"
          :loading="loadingBtn"
          class="rounded-lg v-btn-confirm"
          :disabled="!ableToCreate"
          @click="onSubmit"
          depressed
        >
          Nhập file
        </v-btn>
      </div>
      <input
        id="files-input-news-create"
        class="d-none"
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        @change="onFileChange"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import handleFiles from "@/mixins/handleFiles.js"
import api from "@/services"
import { mapState } from "vuex"
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    cancelAction: {
      type: Function,
      default() {},
    },
    confirmAction: {
      type: Function,
      default() {},
    },
  },
  components: {},
  mixins: [handleFiles],
  data() {
    return {
      dialog: false,
      loadingBtn: false,
      submitClicked: false,
      fileClipBoard: [],
      fileLimit: {
        size: 5 * 1024 * 1024,
      },
    }
  },
  computed: {
    ...mapState({}),
    ableToCreate() {
      return !!this.fileClipBoard.length
      // return true
    },
    listOrderTypesByGate() {
      const rslt = this.listGates.find((o) => o.id === this.gate_id)
      return rslt ? rslt.order_types || [] : []
    },
    errorObjUnder() {
      const obj = {}
      if (!this.fileClipBoard || !this.fileClipBoard.length) {
        obj.name = "Không được để trống file"
      }

      return obj
    },
    errorObj() {
      return this.submitClicked ? this.errorObjUnder : {}
    },
  },
  watch: {
    isVisible() {
      this.resetData()
      this.dialog = this.isVisible
    },
  },
  mounted() {},
  methods: {
    removeFile(idx) {
      this.fileClipBoard.splice(idx, 1)
    },
    addFile() {
      document.querySelector("#files-input-news-create").value = null
      document.querySelector("#files-input-news-create").click()
    },
    clearCacheFile() {
      try {
        document.getElementById("files-input-news-create").value = null
      } catch (error) {
        console.log(error)
      }
    },
    onFileChange(event) {
      const files = event.target.files
      for (const file of files) {
        if (file.size > this.fileLimit.size) {
          this.$store.commit(
            "toast/getError",
            file.name + ": " + this.sysLanguage.snackbar.fileOverSizeLimit,
          )
        } else {
          this.fileClipBoard = []
          const reader = new FileReader()
          reader.onload = (event) => {
            // const img = document.createElement("img")
            // img.src = event.target.result
            // const { width, height } = await getImageDimensions(img);
            const _id = `${new Date().getTime()}_${Math.random()}`

            this.fileClipBoard.push({
              _id,
              original_name: file.name,
              src: event.target.result,
              extension: file.name.split(".").pop(),
              blob: file,
              sizes: file.size,
              status: "queue",
            })
          }
          reader.readAsDataURL(file)
        }
      }
    },
    resetData() {
      this.submitClicked = false
      this.fileClipBoard = []
    },

    // USER HANDLER
    async onSubmit() {
      this.submitClicked = true
      if (Object.keys(this.errorObjUnder).length) {
        return
      }

      const formData = new FormData()
      formData.append("expense_config_file", this.fileClipBoard[0].blob)
      this.loadingBtn = true
      const res = await api.expenseConfigurations.imports(formData)
      this.loadingBtn = false
      if (!res) {
        this.$store.commit(
          "toast/getError",
          res.data.message || "Thao tác thất bại",
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit(
            "toast/getError",
            res.data.message || "Thao tác thất bại",
          )
          return
        }
        this.$store.commit("toast/getSuccess", "Thêm file cấu hình thành công")
        this.$store.commit(
          "toast/getWarning",
          "File cấu hình sẽ mất thời gian để xử lý, vui lòng đợi đến khi thành công",
        )
        this.confirmAction()
        this.cancelAction()
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
    openExample() {
      if (process.env) {
        const url = process.env.VUE_APP_EXPENSE_CONFIG_EXAMPLE_URL
        const a = document.createElement("a")
        a.setAttribute("href", url)
        a.setAttribute("target", "_blank")
        a.setAttribute(
          "download",
          `expense_config_example_${new Date().getTime()}.xlsx`,
        )
        document.body.appendChild(a)
        a.click()
        a.remove()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.create-position-dialog {
  border-radius: 8px;
  .dialog-title {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 28px;
  }
  .dialog-body-content {
    display: flex;
    padding: 0 24px;
    max-height: calc(90vh - 70px);
    .left-side {
      width: 100%;
      padding: 20px 8px 40px;
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      .input-form-border {
        border-radius: 8px;
        border: 1px solid;
        height: 48px;
        padding: 12px 8px;
        font-weight: 400;
        font-size: 16px;
        &.v-texarea-normalize {
          height: auto;
        }
      }
      .input-block {
        display: flex;
        flex-flow: column;
        &.flex-input {
          flex-flow: row;
        }
        .input {
          &:focus {
            outline: none;
          }
        }
        .input-form-label {
          letter-spacing: 0.0015em;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
    .info-input {
      display: flex;
      align-items: center;
      border: 1px solid;
      border-radius: 8px;
      height: 48px;
      padding: 10px 12px;
    }
  }
}
</style>
<style lang="scss">
.create-position-dialog {
  border-radius: 8px !important;
}
.single-date-blank {
  border: none;
  .mx-input-wrapper {
    width: 100%;
    height: 100%;
  }
  .mx-input {
    border: none !important;
    box-shadow: none !important;
    &::placeholder {
      font-size: 16px;
    }
  }
}

.file-shown {
  border: 2px dashed #60a5fa;
  border-radius: 8px;
  &:hover {
    background-color: #60a5fa1f;
  }
}
</style>
<style lang="scss" scoped>
.single-date-v2 {
  border: none;
  .mx-input-wrapper {
    width: 100%;
    height: 100%;
  }
  .mx-input {
    border: none !important;
  }
}
</style>
