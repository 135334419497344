<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="860"
    style="border-radius: 8px"
  >
    <v-card class="create-position-dialog">
      <v-card-title class="primary px-4">
        <div class="d-flex align-center">
          <v-btn color="white" icon @click="cancelAction"
            ><v-icon size="20px">mdi-close</v-icon></v-btn
          >
          <span
            class="white--text dialog-title"
            style="font-size: 16px !important; font-weight: 400"
            >Cập nhật Công ty</span
          >
        </div>
      </v-card-title>
      <div class="dialog-body-content">
        <v-row class="left-side scroll-y-mod_big">
          <v-col cols="12" md="6" class="input-block pb-2">
            <label class="input-form-label font-weight-500 black--text pb-2">
              Tên công ty <span class="semantic_error--text">*</span></label
            >
            <input
              v-model="name"
              type="text"
              class="input input-form-border neutral_color_sub3 bg-white mb-2"
              :class="errorObj.name ? 'error-border' : ''"
              placeholder="Công ty"
            />
            <p
              v-if="errorObj.name"
              class="semantic_error--text error-msg-small mb-0"
            >
              {{ errorObj.name }}
            </p>
          </v-col>
          <v-col cols="12" md="6" class="input-block pb-2">
            <label class="input-form-label font-weight-500 black--text pb-2">
              Mã công ty</label
            >
            <input
              v-model="code"
              type="text"
              class="input input-form-border neutral_color_sub3 bg-white mb-2"
              placeholder="Mã công ty"
            />
          </v-col>
          <v-col cols="12" md="6" class="input-block pb-2">
            <label class="input-form-label font-weight-500 black--text pb-2">
              Mã số thuế</label
            >
            <input
              v-model="enterprise_gdt_code"
              type="text"
              class="input input-form-border neutral_color_sub3 bg-white mb-2"
              placeholder="Mã số thuế"
            />
          </v-col>
          <v-col cols="12" md="6" class="input-block pb-2">
            <label class="input-form-label font-weight-500 black--text pb-2">
              Số điện thoại công ty
              <span class="semantic_error--text">*</span></label
            >
            <input
              v-model="phone"
              type="text"
              class="input input-form-border neutral_color_sub3 bg-white mb-2"
              :class="errorObj.phone ? 'error-border' : ''"
              placeholder="Số điện thoại công ty"
            />

            <p
              v-if="errorObj.phone"
              class="semantic_error--text error-msg-small mb-0"
            >
              {{ errorObj.phone }}
            </p>
          </v-col>
          <v-col cols="12" class="input-block pb-2">
            <label class="input-form-label font-weight-500 black--text pb-2">
              Địa chỉ công ty</label
            >
            <textarea
              v-model="address"
              type="text"
              class="input input-form-border neutral_color_sub3 bg-white mb-2"
              rows="5"
              placeholder="Địa chỉ công ty"
            ></textarea>
          </v-col>

          <v-col cols="12" md="6" class="input-block pb-2">
            <label class="input-form-label font-weight-500 black--text pb-2">
              Giấy phép đăng ký kinh doanh
            </label>
            <FileUploaderWithPreview
              :init-data="business_license_files || []"
              :title="''"
              :suffixIconName="'bi:upload'"
              :local-file-removed="fileRemoved"
              @on-change="onChangeLicenseFiles"
              @removeLocalFile="removeLocalFile"
            />
            <p
              v-if="errorObj.business_license_files"
              class="semantic_error--text error-msg-small mb-0"
            >
              {{ errorObj.business_license_files }}
            </p>
          </v-col>
          <v-col cols="12" md="6" class="input-block pb-2">
            <label class="input-form-label font-weight-500 black--text pb-2">
              Dấu khống khách hàng
            </label>
            <FileUploaderWithPreview
              :init-data="company_seal_files || []"
              :title="''"
              :suffixIconName="'bi:upload'"
              :local-file-removed="fileRemoved"
              @on-change="onChangeSealFiles"
              @removeLocalFile="removeLocalFile"
            />
            <p
              v-if="errorObj.company_seal_files"
              class="semantic_error--text error-msg-small mb-0"
            >
              {{ errorObj.company_seal_files }}
            </p>
          </v-col>
        </v-row>
      </div>
      <!-- ACTIONS -->
      <div class="news_block_bg py-2 px-3 group-btn-add-user mt-1 d-flex">
        <v-btn
          :disabled="loadingBtn"
          :color="'neutral_color_sub4 neutral_color_sub2--text'"
          class="rounded-lg v-btn-confirm ml-auto mr-2"
          @click="cancelAction"
          depressed
        >
          {{ sysLanguage.button.cancel }}
        </v-btn>
        <v-btn
          :color="'primary'"
          :loading="loadingBtn"
          class="rounded-lg v-btn-confirm"
          :disabled="!ableToCreate"
          @click="onSubmit"
          depressed
        >
          {{ sysLanguage.button.save }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import handleFiles from "@/mixins/handleFiles.js"
import FileUploaderWithPreview from "@/components/FileHandle/FileUploaderWithPreview"
import api from "@/services"
import { mapState } from "vuex"
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    cancelAction: {
      type: Function,
      default() {},
    },
    confirmAction: {
      type: Function,
      default() {},
    },
    selectedData: {
      type: Object,
      default() {},
    },
  },
  components: { FileUploaderWithPreview },
  mixins: [handleFiles],
  data() {
    return {
      dialog: false,
      loadingBtn: false,
      submitClicked: false,
      company_id: null,
      department_id: null,

      business_license_files: [],
      company_seal_files: [],
      fileRemoved: [],
      name: "",
      code: "",
      phone: "",
      enterprise_gdt_code: "",
      address: "",
    }
  },
  computed: {
    ...mapState({
      listUsers: (state) => state.users.listUsers,
    }),
    ableToCreate() {
      // return !!this.name && !!String(this.name).trim()
      return true
    },

    errorObjUnder() {
      const obj = {}
      if (!this.name || !String(this.name).trim()) {
        obj.name = "Không được để trống tên công ty"
      }
      if (!this.phone) {
        obj.phone = "Không được để trống số điện thoại"
      }

      return obj
    },
    errorObj() {
      return this.submitClicked ? this.errorObjUnder : {}
    },
  },
  watch: {
    isVisible() {
      this.resetData()
      this.dialog = this.isVisible
      this.syncData()
    },
    selectedData: {
      deep: true,
      handler() {
        this.syncData()
      },
    },
  },
  mounted() {
    this.syncData()
  },
  methods: {
    syncData() {
      if (this.selectedData && this.selectedData.id) {
        this.name = this.selectedData.name
        this.code = this.selectedData.code
        this.phone = this.selectedData.phone
        this.enterprise_gdt_code = this.selectedData.enterprise_gdt_code
        this.address = this.selectedData.address

        this.company_seal_files = this.selectedData.company_seal_files || []
        this.business_license_files =
          this.selectedData.business_license_files || []
      }
    },
    resetData() {
      this.submitClicked = false
      this.company_id = null
      this.department_id = null
      this.company_seal_files = []
      this.business_license_files = []
      this.fileRemoved = []
      this.name = ""
      this.code = ""
      this.phone = ""
      this.enterprise_gdt_code = ""
      this.address = ""
    },

    // USER HANDLER
    async onSubmit() {
      this.submitClicked = true
      if (Object.keys(this.errorObjUnder).length) {
        return
      }
      const body = {
        name: this.name,
        code: this.code,
        phone: this.phone,
        enterprise_gdt_code: this.enterprise_gdt_code,
        address: this.address,
        seal_file_ids: [...this.company_seal_files.map((o) => o.id)],
        business_license_file_ids: [
          ...this.business_license_files.map((o) => o.id),
        ],
      }
      this.loadingBtn = true
      const res = await api.companyManagement.update(this.selectedData.id, body)
      this.loadingBtn = false
      if (!res) {
        this.$store.commit(
          "toast/getError",
          this.sysLanguage.snackbar.actionFailDefault,
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          return
        }
        this.$store.commit(
          "toast/getSuccess",
          this.sysLanguage.snackbar.actionSuccessDefault,
        )
        this.confirmAction()
        this.cancelAction()
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },

    removeLocalFile(item) {
      if (item.id) {
        this.fileRemoved.push(item.id)
      }
    },
    onChangeLicenseFiles(arr) {
      this.business_license_files = [...arr]
    },
    onChangeSealFiles(arr) {
      this.company_seal_files = [...arr]
    },
  },
}
</script>
<style lang="scss" scoped>
.create-position-dialog {
  border-radius: 8px;
  .dialog-title {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 28px;
  }
  .dialog-body-content {
    display: flex;
    padding: 0 24px;
    max-height: calc(90vh - 70px);
    .left-side {
      width: 100%;
      padding: 20px 8px 40px;
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;
      .input-form-border {
        border-radius: 8px;
        border: 1px solid;
        height: 48px;
        padding: 12px 8px;
        font-weight: 400;
        font-size: 16px;
        &.v-texarea-normalize {
          height: auto;
        }
      }
      .input-block {
        display: flex;
        flex-flow: column;
        &.flex-input {
          flex-flow: row;
        }
        .input {
          &:focus {
            outline: none;
          }
        }
        .input-form-label {
          letter-spacing: 0.0015em;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
    .info-input {
      display: flex;
      align-items: center;
      border: 1px solid;
      border-radius: 8px;
      height: 48px;
      padding: 10px 12px;
    }
    textarea {
      min-height: 96px;
    }
  }
}
</style>
<style lang="scss">
.create-position-dialog {
  border-radius: 8px !important;
}
</style>
