<template>
  <div class="table-wrapper">
    <table class="table-container main-page-table w-100 white users-table">
      <thead class="primary white--text">
        <td
          class="text-center primary"
          style="
            position: sticky;
            left: 0;
            z-index: 2;
            max-width: 44px;
            min-width: 44px;
          "
        >
          <label
            class="cursor-pointer d-block w-full body-md px-0 py-1 flex items-center justify-center gap-2 rounded"
            @click="toggleSelectAll"
          >
            <v-icon v-show="isSelectAll" class="text-white" size="20"
              >mdi-checkbox-marked</v-icon
            >
            <v-icon v-show="!isSelectAll" class="text-white" size="20"
              >mdi-checkbox-blank-outline</v-icon
            >
          </label>
        </td>
        <td
          class="text-center primary"
          style="min-width: 200px; position: sticky; left: 44px; z-index: 2"
        >
          Tên đầu mục
        </td>
        <td class="text-center primary" style="min-width: 120px">
          Đơn vị tính
        </td>
        <!-- <td class="text-center" style="min-width: 200px">Loại chi phí</td> -->

        <th
          v-for="item in listVehicleTypes"
          :key="item.id"
          scope="col"
          class=""
          style="min-width: 120px"
        >
          {{ item.name }}
        </th>
      </thead>
      <tbody class="white" :class="loadingTable ? 'body-loading' : ''">
        <div class="absolute-loading" v-if="loadingTable">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <tr v-for="item in items" :key="item.id">
          <td class="px-2 white" style="position: sticky; left: 0; z-index: 2">
            <div
              class="d-flex gap-2 pointer justify-center hover-show-copy w-100 px-1"
              @click="item.auto_check = !item.auto_check"
            >
              <v-icon
                v-show="item.auto_check"
                class="text-primary-500"
                size="20"
                >mdi-checkbox-marked</v-icon
              >
              <v-icon
                v-show="!item.auto_check"
                class="text-primary-500"
                size="20"
                >mdi-checkbox-blank-outline</v-icon
              >
            </div>
          </td>
          <td
            class="text-center white"
            style="position: sticky; left: 44px; z-index: 2"
          >
            <p class="mb-0 w-100 text-center">
              {{ item ? item.name || "-/-" : "-/-" }}
            </p>
          </td>
          <td class="text-center" style="max-width: 200px; width: 200px">
            <div class="border-b config-inp" style="height: 30px">
              <select-auto
                :placeholder="'-- Chọn đơn vị --'"
                :onChange="
                  (_val) => {
                    onChangeUnit(_val, item)
                  }
                "
                :default-value="+item.unit_id"
                :options="listUnits"
                :clearAble="false"
                :custom-class="'v-autocomplete-no-border v-autocomplete-no-min-height  w-100 v-autocomplete-size-14'"
              />
            </div>
          </td>

          <td
            class="text-center px-1"
            v-for="price in item.vehicle_prices"
            :key="price.id"
          >
            <input
              v-model="price.price"
              type="text"
              class="d-block w-100 py-1 px-3 text-center config-inp input-no-focus-outline"
              placeholder="Đơn giá (VNĐ)"
              @keyup="handlerKeyPress"
            />
          </td>
        </tr>

        <tr v-if="isFirstLoading">
          <td colspan="100" class="text-center py-5 pb-0">
            Đang tải dữ liệu...
          </td>
        </tr>
        <tr v-if="isFirstLoading">
          <td colspan="100" class="text-center py-5 pb-0">
            <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
          </td>
        </tr>
        <tr
          v-if="!isFirstLoading && (!listData || !listData.length)"
          class="no-data"
        >
          <td colspan="100" class="text-center py-5">
            <EmptyBody :isSearch="isSearch" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex items-center justify-center group-btn py-2">
      <v-btn
        color="primary"
        depressed
        style="border-radius: 4px"
        class="low-letter-spacing d-block font-size-14"
        @click="submitData"
        :loading="loadingBtn"
      >
        Lưu
      </v-btn>
    </div>
  </div>
</template>

<script>
import { formatDateDMY } from "@/helpers/dateFormater.js"
import objHandlerMixins from "@/mixins/objHandlerMixins.js"
import { getCurrency, currencyToNumber } from "@/helpers/FormatnParse.js"
import { mapState } from "vuex"
import api from "@/services"
import SelectAuto from "../Filter/SelectAuto.vue"
export default {
  mixins: [objHandlerMixins],
  props: {
    listData: {
      type: Array,
      default() {
        return []
      },
    },
    isFirstLoading: {
      type: Boolean,
      default: true,
    },
    loadingTable: {
      type: Boolean,
      default: true,
    },
    detailData: {
      type: Object,
      default() {},
    },
    getDetailData: {
      type: Function,
      default() {},
    },
  },
  data() {
    return {
      refprice: 1,
      items: [],
      loadingBtn: false,
    }
  },
  computed: {
    ...mapState({
      listUnits: (state) => state.listUnits,
    }),
    listVehicleTypes() {
      try {
        const ids = [...new Set(this.detailData.vehicle_types.map((o) => o.id))]

        return ids.map((o) => {
          return this.detailData.vehicle_types.find((k) => k.id === o)
        })
      } catch (error) {
        return []
      }
    },
    isSelectAll() {
      return this.items.length && this.items.every((o) => o.auto_check)
    },
  },
  watch: {
    listData: {
      deep: true,
      handler() {
        this.syncData()
      },
    },
  },
  mounted() {
    this.syncData()
  },
  methods: {
    formatDateDMY,

    syncData() {
      this.items = this.listData.map((o) => {
        const obj = {
          ...o,
          auto_check: o.auto_check || false,

          unit_id: null,
        }
        obj.vehicle_prices = obj.vehicle_prices || []
        const vehicle_prices_computed = this.listVehicleTypes.map((k) => {
          return (
            obj.vehicle_prices.find((i) => i.vehicle_type_id === k.id) || {
              price: "",
              vehicle_type_id: k.id,
            }
          )
        })
        obj.vehicle_prices = [...vehicle_prices_computed].map((k) => ({
          ...k,
          price: k.price ? getCurrency(k.price) : null,
        }))
        return obj
      })
    },
    handlerKeyPress() {
      clearTimeout(this.refprice)
      // @ts-ignore
      this.refprice = setTimeout(() => {
        this.items = this.items.map((o) => ({
          ...o,
          vehicle_prices: o.vehicle_prices.map((k) => ({
            ...k,
            price: k.price ? getCurrency(k.price) : null,
          })),
        }))
      }, 200)
    },
    async submitData() {
      const data = this.items.map((o) => {
        return {
          expense_category_id: o.id,
          default_check: +o.auto_check,
          unit_id: +o.unit_id,
          vehicle_prices: o.vehicle_prices.map((k) => ({
            vehicle_type_id: k.vehicle_type_id,
            price: k.price ? currencyToNumber(k.price) : 0,
          })),
        }
      })
      this.loadingBtn = true
      const res = await api.expenseConfigurations.configExpenseItems(
        this.detailData.id,
        {
          expense_items: data,
        },
      )
      this.loadingBtn = false
      if (!res) {
        this.$store.commit(
          "toast/getError",
          res.data.message || "Thao tác thất bại",
        )
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit(
            "toast/getError",
            res.data.message || "Thao tác thất bại",
          )
          return
        }
        this.$store.commit("toast/getSuccess", "Thêm cấu hình thành công")
        this.getDetailData()
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
    toggleSelectAll() {
      if (this.isSelectAll) {
        this.items = this.items.map((o) => ({ ...o, auto_check: false }))
      } else {
        this.items = this.items.map((o) => ({ ...o, auto_check: true }))
      }
    },
    onChangeUnit(_val, item) {
      const matchedIdx = this.items.findIndex((o) => o.id === item.id)
      if (matchedIdx !== -1) {
        this.items.splice(matchedIdx, 1, {
          ...item,
          unit_id: _val,
        })
      }
    },
  },
  components: { SelectAuto },
}
</script>
<style lang="scss" scoped>
.users-table {
  tbody {
    td {
      font-size: 14px !important;
    }
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }
}
.table-wrapper {
  max-width: 100%;
  overflow: scroll;
  height: calc(100vh - 230px);
  padding-bottom: 0 !important;
  &::-webkit-scrollbar {
    width: 0px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #e6e6e6;
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background: rgb(96, 96, 96);
  }
}
.v-application {
  .body-loading {
    opacity: 0.8;
    /* background-color: rgba(239, 240, 240, 0.563) !important; */
    pointer-events: none;
    position: relative;
  }
  .absolute-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    min-height: 200px;
    background-color: rgba(239, 240, 240, 0.563) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.config-inp {
  border-bottom: 1px solid #b0b0b0;
  &:focus {
    border-color: #048033;
  }
}
.group-btn {
  position: sticky;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: #fff;
}
</style>
<style>
.limit-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 450px;
  display: block;

  -webkit-box-orient: vertical;
}
</style>
<style lang="scss" scoped>
.btn-copy {
  opacity: 0;
}
.hover-show-copy:hover .btn-copy {
  opacity: 1;
}
</style>
