export const parseNewsStatus = (_statusId) => {
  switch (_statusId) {
    case 1:
      return "Mới"
    case 2:
      return "Đã đăng"
    case 3:
      return "Bị thu hồi"

    default:
      return "Không rõ"
  }
}
export const parseNewsStatusClass = (_statusId) => {
  switch (_statusId) {
    case 1:
      return "semantic_info"
    case 2:
      return "semantic_success"
    case 3:
      return "semantic_warning"

    default:
      return "unknow_color"
  }
}
export const parseNewsStatusTextClass = (_statusId) => {
  const prefix = parseNewsStatusClass(_statusId)
  return prefix + "--text"
}

// REPORTS
export const parseNewsReportStatus = (_statusId) => {
  switch (_statusId) {
    case 1:
      return "Chưa xử lý"
    case 2:
      return "Đã xóa"
    case 3:
      return "Đã giữ lại"

    default:
      return "Không rõ"
  }
}
export const parseNewsReportStatusClass = (_statusId) => {
  switch (_statusId) {
    case 1:
      return "semantic_warning"
    case 2:
      return "semantic_error"
    case 3:
      return "semantic_info"

    default:
      return "unknow_color"
  }
}
export const parseNewsReportStatusTextClass = (_statusId) => {
  const prefix = parseNewsReportStatusClass(_statusId)
  return prefix + "--text"
}

// USERS
export const parseUsersStatus = (_statusId) => {
  switch (+_statusId) {
    case 1:
      return "Đang hoạt động"
    case 0:
      return "Ngừng hoạt động"
    default:
      return "Không rõ"
  }
}
export const parseUsersStatusClass = (_statusId) => {
  switch (+_statusId) {
    case 1:
      return "semantic_success"
    case 0:
      return "semantic_error"

    default:
      return "unknow_color"
  }
}
export const parseUsersStatusTextClass = (_statusId) => {
  const prefix = parseUsersStatusClass(_statusId)
  return prefix + "--text"
}

export const removeAscentNormal = (str) => {
  if (!str) return `${str}`

  str = str.replace(
    /à|À|á|Á|ạ|Ạ|ả|Ả|ã|Ã|â|Â|ầ|Ầ|ấ|Ấ|ậ|Ậ|ẩ|Ẩ|ẫ|Ẫ|ă|Ă|ằ|Ằ|ắ|Ắ|ặ|Ặ|ẳ|Ẳ|ẵ|Ẵ/g,
    "a",
  )
  str = str.replace(/è|È|é|É|ẹ|Ẹ|ẻ|Ẻ|ẽ|Ẽ|ê|Ê|ề|Ề|ế|Ế|ệ|Ệ|ể|Ể|ễ|Ễ/g, "e")
  str = str.replace(/ì|I|í|Í|ị|Ị|ỉ|Ỉ|ĩ|Ĩ/g, "i")
  str = str.replace(
    /ò|Ò|ó|Ó|ọ|Ọ|ỏ|Ỏ|õ|Õ|ô|Ô|ồ|Ồ|ố|Ố|ộ|Ộ|ổ|Ổ|ỗ|Ỗ|ơ|Ơ|ờ|Ờ|ớ|Ớ|ợ|Ợ|ở|Ở|ỡ|Ỡ/g,
    "o",
  )
  str = str.replace(/ù|Ù|ú|Ú|ụ|Ụ|ủ|Ủ|ũ|Ũ|ư|Ư|ừ|Ừ|ứ|Ứ|ự|Ự|ử|Ử|ữ|Ữ/g, "u")
  str = str.replace(/ỳ|Ỳ|ý|Ý|ỵ|Ỵ|ỷ|Ỷ|ỹ|Ỹ/g, "y")
  str = str.replace(/đ|Đ/g, "d")
  return str
}
export const isContainText = (source, target) => {
  return removeAscentNormal(source.toLowerCase().replaceAll(" ", "")).includes(
    removeAscentNormal(target.toLowerCase()).replaceAll(" ", ""),
  )
}

export const getCurrency = (str, returnString = "") => {
  if (str === null) {
    return returnString || ""
  }
  if (!["string", "number"].includes(typeof str)) {
    console.error("Provided params is not a String or Number type", str)
    return returnString || ""
  }

  if (+str === 0) return `${str}`
  let val = String(str).trim().replaceAll(",", "")
  const isNegative = +val < 0
  let absVal = +String(str).trim().replaceAll(",", "").replaceAll("-", "") + ""
  let hT = absVal.split(".")
  let head = hT[0].split("")
  if (head.length > 3) {
    let index = 3
    while (head.length - index >= 1) {
      head.splice(head.length - index, 0, ",")
      index += 4
    }
  }
  return hT.length > 1
    ? `${isNegative ? "-" : ""}` + head.join("") + "." + hT[1]
    : `${isNegative ? "-" : ""}` + head.join("")
}
export const currencyToNumber = (_str, invalidReturn = "") => {
  try {
    if (!["string", "number"].includes(typeof _str)) {
      return invalidReturn || _str
    }
    const tr = String(_str).replaceAll(",", "")
    if (_str !== 0 && _str !== "0" && !+tr) {
      return invalidReturn || _str
    }
    return parseFloat(tr) || 0
  } catch (error) {
    return 0
  }
}
