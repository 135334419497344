import Vue from "vue"
export const validNumberKeyCodes = [
  231, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 109, 110, 188, 189, 190,
  46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8, 9, 13, 37, 38, 39, 40,
]
export const validNumberIntKeyCodes = [
  231, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 109, 110, 188, 46, 48, 49,
  50, 51, 52, 53, 54, 55, 56, 57, 8, 9, 13, 37, 38, 39, 40,
]
export const validNumberFloatKeyCodes = [
  231, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 109, 110, 188, 190, 46, 48,
  49, 50, 51, 52, 53, 54, 55, 56, 57, 8, 9, 13, 37, 38, 39, 40,
]
Vue.directive("number", {
  mounted(el) {
    el.addEventListener("keyup", (e) => {
      if (
        !validNumberFloatKeyCodes.includes(e.keyCode) &&
        !(
          (e.keyCode === 65 ||
            e.keyCode === 86 ||
            e.keyCode === 67 ||
            e.keyCode === 190) &&
          (e.ctrlKey === true || e.metaKey === true)
        )
      ) {
        e.preventDefault()
      }
      if (e.keyCode === 190 && e.target.value.includes(".")) {
        e.preventDefault()
      }
      console.log("??")
    })

    el.addEventListener("keydown", (e) => {
      if (
        !validNumberFloatKeyCodes.includes(e.keyCode) &&
        !(
          (e.keyCode === 65 ||
            e.keyCode === 86 ||
            e.keyCode === 67 ||
            e.keyCode === 190) &&
          (e.ctrlKey === true || e.metaKey === true)
        )
      ) {
        e.preventDefault()
      }
      if (e.keyCode === 190 && e.target.value.includes(".")) {
        e.preventDefault()
      }
    })

    el.addEventListener("keypress", (e) => {
      if (
        !validNumberFloatKeyCodes.includes(e.keyCode) &&
        !(
          (e.keyCode === 65 ||
            e.keyCode === 86 ||
            e.keyCode === 67 ||
            e.keyCode === 190) &&
          (e.ctrlKey === true || e.metaKey === true)
        )
      ) {
        e.preventDefault()
      }
      if (e.keyCode === 190 && e.target.value.includes(".")) {
        e.preventDefault()
      }
    })
  },
})
Vue.directive("focus", {
  mounted(el) {
    el.focus()
  },
})
