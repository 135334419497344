const repo = (axios) => {
  const prefix = `/api/admin/warehouse-management`
  const handleGETRequest = (uri, payload) => {
    return axios
      .get(`${uri}`, {
        params: { ...payload },
      })
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  }
  const handlePOSTRequest = (uri, payload) => {
    return axios
      .post(`${uri}`, payload)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return err.response
      })
  }
  return {
    getListData(payload) {
      return handleGETRequest(`${prefix}/porter-teams`, payload)
    },
    getDetailData(id, payload) {
      return handleGETRequest(`${prefix}/porter-teams/${id}`, payload)
    },
    create(payload) {
      return handlePOSTRequest(`${prefix}/porter-teams`, payload)
    },
    updateStatus(id, payload) {
      return handlePOSTRequest(`${prefix}/porter-teams/${id}/status`, payload)
    },
    update(id, payload) {
      return handlePOSTRequest(`${prefix}/porter-teams/${id}`, payload)
    },
  }
}
export default repo
