var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table-container main-page-table w-100 white users-table"},[_vm._m(0),_c('tbody',{staticClass:"white",class:_vm.loadingTable ? 'body-loading' : ''},[(_vm.loadingTable)?_c('div',{staticClass:"absolute-loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_vm._l((_vm.listDataComputed),function(data,idx){return _c('tr',{key:data.id},[_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(idx + 1))]),_c('td',[_c('p',{staticClass:"mb-0 limit-line-1 semantic_info--text text-center pointer hover-underline",on:{"click":function($event){return _vm.selectDataToUpdate(data)}}},[_vm._v(" "+_vm._s(data.code || "--/--")+" ")])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.name || "--/--")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.phone || "--/--")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.email || "--/--")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.company ? data.company.name : "--/--")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.status ? data.status.name : "--/--")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.creator ? data.creator.name : "--/--")+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.created_at ? _vm.formatDateDMY(data.created_at) : "--/--")+" ")]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"table-actions-group d-flex",class:("group-status-" + (data.status_id))},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.selectDataToUpdate(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"neutral_color_sub1"}},[_vm._v(" mdi-pencil-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Chỉnh sửa")])]),(data.status && data.status.id === 10)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.toggleStatus(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"semantic_error"}},[_vm._v(" mdi-close-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ngừng hoạt động")])]):_vm._e(),(data.status && data.status.id === 20)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.toggleStatus(data)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"semantic_success"}},[_vm._v(" mdi-check-circle-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Mở hoạt động")])]):_vm._e()],1)])])}),(_vm.isFirstLoading)?_c('tr',[_c('td',{staticClass:"text-center py-5 pb-0",attrs:{"colspan":"100"}},[_vm._v("Đang tải dữ liệu...")])]):_vm._e(),(_vm.isFirstLoading)?_c('tr',[_c('td',{staticClass:"text-center py-5 pb-0",attrs:{"colspan":"100"}},[_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})],1)]):_vm._e(),(!_vm.isFirstLoading && (!_vm.listData || !_vm.listData.length))?_c('tr',{staticClass:"no-data"},[_c('td',{staticClass:"text-center py-5",attrs:{"colspan":"100"}},[_c('EmptyBody',{attrs:{"isSearch":_vm.isSearch}})],1)]):_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"primary white--text"},[_c('td',{staticClass:"text-center",staticStyle:{"width":"50px"}},[_vm._v("STT")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"150px"}},[_vm._v("Mã người dùng")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"170px"}},[_vm._v("Họ và tên")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"120px"}},[_vm._v("Số điện thoại")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"138px"}},[_vm._v("Email")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"132px"}},[_vm._v("Công ty")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"132px"}},[_vm._v("Trạng thái")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"162px"}},[_vm._v("Người tạo")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"132px"}},[_vm._v("Thời gian tạo")]),_c('td',{staticClass:"text-center",staticStyle:{"width":"62px"}})])}]

export { render, staticRenderFns }