<template>
  <table class="table-container main-page-table w-100 white users-table">
    <thead class="primary white--text">
      <td class="text-center">Đầu mục doanh thu</td>
      <td class="text-center">Điều kiện ghi nhận</td>
      <td class="text-center">Loại tiêu chuẩn</td>
      <td class="text-center">Phân loại chi phí</td>
    </thead>
    <tbody class="white" :class="loadingTable ? 'body-loading' : ''">
      <div class="absolute-loading" v-if="loadingTable">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <tr v-for="data in listData" :key="data.id">
       

        <td class="text-center">
          <div class="d-flex gap-2 justify-center hover-show-copy w-100">
            <p class="mb-0 limit-line-1 text-left pl-2">
              {{ data.revenue_category ? data.revenue_category.name : "-/-" }}
            </p>
          </div>
        </td>
        <td class="text-center">
          <p class="mb-0 w-100 text-center">
            {{ data.visible_classification ? data.visible_classification.name || "-/-" : "-/-" }}
          </p>
        </td>
        <td class="text-center">
          <p class="mb-0 w-100 text-center">
            {{ data.standard_type ? data.standard_type.name || "-/-" : "-/-" }}
          </p>
        </td>
        <td class="text-center">
          <p class="mb-0 w-100 text-center">
            {{
              data.customer_fee_classification
                ? data.customer_fee_classification.name || "-/-"
                : "-/-"
            }}
          </p>
        </td>
       
      </tr>

      <tr v-if="isFirstLoading">
        <td colspan="100" class="text-center py-5 pb-0">Đang tải dữ liệu...</td>
      </tr>
      <tr v-if="isFirstLoading">
        <td colspan="100" class="text-center py-5 pb-0">
          <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
        </td>
      </tr>
      <tr
        v-if="!isFirstLoading && (!listData || !listData.length)"
        class="no-data"
      >
        <td colspan="100" class="text-center py-5">
          <EmptyBody :isSearch="isSearch" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import objHandlerMixins from "@/mixins/objHandlerMixins.js"
export default {
  mixins: [objHandlerMixins],
  props: {
    listData: {
      type: Array,
      default() {
        return []
      },
    },
    isFirstLoading: {
      type: Boolean,
      default: true,
    },
    loadingTable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
  
  },
  methods: {
  
  },
}
</script>
<style lang="scss" scoped>
.users-table {
  tbody {
    td {
      font-size: 14px !important;
    }
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }
}