<template>
  <div class="mb-2 custom-bg mx-2 box-shadow--mod-lighter">
    <v-list-group :value="true" class="">
      <template v-slot:activator>
        <v-list-item-title>{{ application.name }}</v-list-item-title>
      </template>
      <div class="px-1 py-2">
        <v-row class="my-0 mx-0" v-for="item in listLinked" :key="item.id">
          <v-col cols="6" class="py-0">
            <div class="input-block read-only pb-2">
              <label class="input-form-label black--text pb-2">
                Chi phí <span class="semantic_error--text">*</span>
              </label>
              <div
                class="info-input px-0 neutral_color_sub3--text"
                :class="
                  flagSubmit && !item.expense_category_id ? ' error-border' : ''
                "
              >
                <select-auto
                  :placeholder="'-- Chọn loại hình --'"
                  :onChange="
                    (_val) => {
                      onChangeExpense(_val, item)
                    }
                  "
                  :default-value="+item.expense_category_id"
                  :customNoDataMessage="`Không có danh mục để chọn. Liên hệ IT để đượ trợ giúp`"
                  :options="application.expense_categories"
                  :clearAble="false"
                  :custom-class="'v-autocomplete-no-border w-100 v-autocomplete-size-14'"
                />
              </div>
            </div>
          </v-col>
          <v-col cols="6" class="py-0 d-flex items-center">
            <div class="input-block read-only pb-2 w-100">
              <label class="input-form-label black--text pb-2">
                Doanh thu <span class="semantic_error--text">*</span>
              </label>
              <div
                class="info-input px-0 neutral_color_sub3--text"
                :class="
                  flagSubmit && !item.revenue_category_id ? ' error-border' : ''
                "
              >
                <select-auto
                  :key="item.revenue_category_id"
                  :placeholder="'-- Chọn loại hình --'"
                  :onChange="
                    (_val) => {
                      onChangeRevenue(_val, item)
                    }
                  "
                  :default-value="+item.revenue_category_id"
                  :customNoDataMessage="`Không có danh mục để chọn. Liên hệ IT để đượ trợ giúp`"
                  :options="application.revenue_categories"
                  :clearAble="false"
                  :custom-class="`v-autocomplete-no-border w-100 v-autocomplete-size-14`"
                />
              </div>
            </div>
            <v-tooltip v-if="listLinked.length > 1" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  class="pointer text-red-500"
                  size="24px"
                  style="
                    min-width: 24px;
                    height: 24px;
                    align-self: flex-end;
                    margin-bottom: 12px;
                  "
                  @click="removeItem(item)"
                >
                  mdi-trash-can-outline
                </v-icon>
              </template>
              <span>Xoá</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-btn
          color="primary"
          depressed
          style="border-radius: 4px"
          class="low-letter-spacing ml-auto mr-3 d-block font-size-14"
          @click="addNewRow"
        >
          THÊM ĐẦU MỤC
        </v-btn>
      </div>
    </v-list-group>
  </div>
</template>

<script>
import SelectAuto from "../Filter/SelectAuto.vue"
export default {
  components: { SelectAuto },
  props: {
    application: {
      type: Object,
      default() {
        return {}
      },
    },
    childShake: {
      type: Number,
      default: 0,
    },
    flagSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listLinked: [
        {
          id: `fk-${this.application.id}`,
          expense_category_id: null,
          revenue_category_id: null,
        },
      ],
    }
  },
  watch: {
    childShake() {
      this.submitData()
    },
  },
  computed: {
    ListExpense() {
      const selectedCateIds = this.listLinked
        .filter((o) => o.expense_category_id)
        .map((o) => o.expense_category_id)
      return this.application.expense_categories.map((o) => {
        return { ...o, disabled: selectedCateIds.includes(o.id) }
      })
    },
    ListRevenue() {
      const selectedCateIds = this.listLinked
        .filter((o) => o.revenue_category_id)
        .map((o) => o.revenue_category_id)
      return this.application.revenue_categories.map((o) => {
        return { ...o, disabled: selectedCateIds.includes(o.id) }
      })
    },
    ableToSubmit() {
      return this.listLinked.every(
        (o) => o.expense_category_id && o.revenue_category_id,
      )
    },
  },
  methods: {
    onChangeExpense(_val, item) {
      const matchedIdx = this.listLinked.findIndex((o) => o.id === item.id)
      if (matchedIdx !== -1) {
        this.listLinked.splice(matchedIdx, 1, {
          ...item,
          expense_category_id: _val,
        })
      }
    },
    onChangeRevenue(_val, item) {
      const matchedIdx = this.listLinked.findIndex((o) => o.id === item.id)
      if (matchedIdx !== -1) {
        this.listLinked.splice(matchedIdx, 1, {
          ...item,
          revenue_category_id: _val,
        })
      }
    },
    addNewRow() {
      this.listLinked.push({
        id: `fk-${new Date().getTime()}`,
        expense_category_id: null,
        revenue_category_id: null,
      })
    },
    submitData() {
      if (!this.ableToSubmit) {
        this.$store.commit(
          "toast/getError",
          `Lỗi nhập liệu thu chi tại hệ thống: ${this.application.name}. Vui lòng kiểm tra lại.`,
        )
        this.$emit("failed", { ...this.application })
        return
      }
      this.$emit("collect", {
        ...this.application,
        linked_items: [...this.listLinked],
      })
    },
    removeItem(item) {
      if (this.listLinked.length <= 1) {
        return
      }
      this.listLinked = this.listLinked.filter((o) => o.id !== item.id)
    },
  },
}
</script>

<style lang="scss">
.custom-bg {
  // .v-list-group--active {
  //   background-color: #000 !important;
  // }
  border: 1px solid #ebebeb;
  border-radius: 8px;
  overflow: hidden;
  .v-list-item {
    background-color: #0d7042 !important;
    border-radius: 8px 8px 0 0;
    color: #fff !important;
    .v-icon {
      color: #fff !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.info-input {
  display: flex;
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  height: 40px;
  padding: 4px 12px;
}
</style>
