import axios from "axios"
import internal from "./internal"
import user from "./user"
import annoucement from "./annoucement"
import chat from "./chat"
import users from "./users"
import noti from "./noti"
import positions from "./positions"
import companies from "./companies"
import companyManagement from "./companyManagement"
import departments from "./departments"
import applications from "./applications"
import expenseClassifications from "./expenseClassifications"
import roleManagement from "./roleManagement"
import applicationManagement from "./applicationManagement"
import userGroup from "./userGroup"
import fileHandle from "./fileHandle"
import expenseCategories from "./expenseCategories"
import revenueCategories from "./revenueCategories"
import expenseConfigurations from "./expenseConfigurations"
import warehouseConfigurations from "./warehouseConfigurations"
import warehousePoterTeamManagement from "./warehousePoterTeamManagement"
import warehouseLocationManagement from "./warehouseLocationManagement"
import warehouseCustomerAccountManagement from "./warehouseCustomerAccountManagement"

// const axiosInstance = axios.create()
// console.log(axiosInstance.defaults.headers.common)
const services = ((axios) => {
  return {
    internal: internal(axios),
    user: user(axios),
    users: users(axios),
    userGroup: userGroup(axios),
    fileHandle: fileHandle(axios),
    noti: noti(axios),
    chat: chat(axios),
    positions: positions(axios),
    companies: companies(axios),
    companyManagement: companyManagement(axios),
    departments: departments(axios),
    applications: applications(axios),
    expenseClassifications: expenseClassifications(axios),
    roleManagement: roleManagement(axios),
    applicationManagement: applicationManagement(axios),
    annoucement: annoucement(axios),
    expenseCategories: expenseCategories(axios),
    revenueCategories: revenueCategories(axios),
    expenseConfigurations: expenseConfigurations(axios),
    warehouseConfigurations: warehouseConfigurations(axios),
    warehousePoterTeamManagement: warehousePoterTeamManagement(axios),
    warehouseLocationManagement: warehouseLocationManagement(axios),
    warehouseCustomerAccountManagement:
      warehouseCustomerAccountManagement(axios),
  }
})(axios)
export default services
