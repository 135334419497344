<template>
  <v-dialog v-model="dialog" max-width="1240" style="border-radius: 8px">
    <v-card class="create-position-dialog">
      <div class="dialog-body-content px-5 py-5" style="overflow-y: scroll">
        <div class="content-slot-header">Danh sách files cấu hình thu chi</div>
        <div class="panigate-block px-2">
          <Pagination
            :loading="loadingSearch"
            class="mt-0"
            :pagination-sync="pagination"
            @handlePageSizeChange="pagePaginationChange"
            @handlePageChange="pagePaginationChange"
          />
        </div>
        <div class="left-side scroll-y-mod_big">
          <ExpenseFileConfigTableVue
            :list-data="listData"
            :isFirstLoading="isFirstLoading"
            :loadingTable="loadingSearch"
          />
        </div>
      </div>
      <!-- ACTIONS -->
      <div class="news_block_bg py-2 px-3 group-btn-add-user mt-1 d-flex">
        <v-btn
          :disabled="loadingBtn"
          :color="'neutral_color_sub4 neutral_color_sub2--text'"
          class="rounded-lg v-btn-confirm ml-auto mr-2"
          @click="cancelAction"
          depressed
        >
          Đóng
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import handleFiles from "@/mixins/handleFiles.js"
import api from "@/services"
import { mapState } from "vuex"
import ExpenseFileConfigTableVue from "../Table/ExpenseFileConfigTable.vue"
import Pagination from "@/components/Layout/Pagination.vue"
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    cancelAction: {
      type: Function,
      default() {},
    },
    confirmAction: {
      type: Function,
      default() {},
    },
  },
  components: { ExpenseFileConfigTableVue, Pagination },
  mixins: [handleFiles],
  data() {
    return {
      dialog: false,
      loadingBtn: false,
      submitClicked: false,
      loadingSearch: false,
      isFirstLoading: true,
      pagination: {
        total: 1,
        total_pages: 1,
        per_page: 15,
        page: 1,
      },
      listData: [],
    }
  },
  computed: {
    ...mapState({}),
  },
  watch: {
    isVisible() {
      this.resetData()
      this.dialog = this.isVisible
      if (this.isVisible) {
        this.getListData()
      }
    },
  },
  mounted() {},
  methods: {
    resetData() {
      this.submitClicked = false
      this.loadingSearch = true
    },
    pagePaginationChange(_val) {
      this.pagination = {
        ...this.pagination,
        per_page: _val.per_page,
        page: _val.page,
      }
      this.getListData()
    },
    async getListData() {
      this.loadingSearch = true
      const query = {
        size: this.pagination.per_page,
        page: this.pagination.page,
      }
      const payload = { ...query }
      const res = await api.expenseConfigurations.getListFileImports(payload)
      this.isFirstLoading = false

      this.loadingSearch = false
      if (!res) {
        this.$store.commit("toast/getError", "Lỗi lấy dữ liệu")
        return
      }
      try {
        if (res.status && res.status >= 400) {
          this.$store.commit("toast/getError", res.data.message)
          console.log(res)
          return
        }
        const dataObj = res.data.data
        this.listData = dataObj.expense_config_imports

        this.pagination = {
          total: dataObj.meta.pagination.total,
          total_pages: dataObj.meta.pagination.total_pages,
          per_page: dataObj.meta.pagination.per_page,
          page: dataObj.meta.pagination.current_page,
        }
      } catch (error) {
        this.$store.commit("toast/getError", `${error}`)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.create-position-dialog {
  border-radius: 8px;
  .dialog-title {
    font-weight: 400;
    font-size: 16px !important;
    line-height: 28px;
  }
  .dialog-body-content {
    padding: 0 24px;
    max-height: calc(90vh - 70px);
  }
}
</style>
<style lang="scss">
.create-position-dialog {
  border-radius: 8px !important;
}
</style>
