<template>
  <v-container class="page-container p-3">
    <TeamsContainer />
  </v-container>
</template>

<script>
import TeamsContainer from "@/components/Container/WarehouseManagement/TeamsContainer.vue"
export default {
  components: { TeamsContainer },
}
</script>
