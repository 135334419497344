<template>
  <v-container class="page-container p-3">
    <RevenueContainer />
  </v-container>
</template>

<script>
import RevenueContainer from "@/components/Container/RevenueContainer.vue"
export default {
  components: { RevenueContainer },
}
</script>
