<template>
  <table class="table-container main-page-table w-100 white users-table">
    <thead class="primary white--text rounded-2">
      <td class="text-center font-size-14">Tên file</td>
      <td class="text-left font-size-14">Trạng thái</td>
      <td class="text-center font-size-14">Lưu ý</td>
      <td class="text-center font-size-14">Thời gian tải lên</td>
      <td class="text-center font-size-14">Thời gian kết thúc</td>
    </thead>
    <tbody class="white" :class="loadingTable ? 'body-loading' : ''">
      <div class="absolute-loading" v-if="loadingTable">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <tr v-for="data in listDataComputed" :key="data.id">
        <td style="max-width: 240px; width: 240px">
          <div class="d-flex gap-2 hover-show-copy w-100">
            <p
              class="mb-0 limit-line-1 text-left pl-2 pointer hover-underline"
              @click="viewDetail(data)"
              :title="data.file ? data.file.original_name : ''"
            >
              {{
                data.file
                  ? formatFileNameLocal(data.file.original_name || "-/-")
                  : "-/-"
              }}
            </p>
            <div class="btn-copy">
              <CopyButton
                :content="data.file ? data.file.original_name : '-/-'"
              ></CopyButton>
            </div>
          </div>
        </td>
        <td class="text-left">
          <p class="mb-0 w-100 text-left">
            {{ data.status ? data.status.name || "-/-" : "-/-" }}
          </p>
        </td>
        <td class="text-left">
          <div
            class="mb-0 w-100 text-left"
            v-html="
              Array.isArray(data.results)
                ? data.results
                    .map((o) => `<p class='mb-0'>${o.message}</p>`)
                    .join('</br>') || '-/-'
                : '-/-'
            "
          ></div>
        </td>
        <td class="text-center">
          {{ formatDateHM(data.created_at) }}
          {{ formatDateDMY(data.created_at) }}
        </td>
        <td class="text-center">
          {{ data.finished_at ? "" : "-/-" }}
          {{ formatDateHM(data.finished_at) }}
          {{ formatDateDMY(data.finished_at) }}
        </td>
      </tr>

      <tr v-if="isFirstLoading">
        <td colspan="100" class="text-center py-5 pb-0">Đang tải dữ liệu...</td>
      </tr>
      <tr v-if="isFirstLoading">
        <td colspan="100" class="text-center py-5 pb-0">
          <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
        </td>
      </tr>
      <tr
        v-if="!isFirstLoading && (!listData || !listData.length)"
        class="no-data"
      >
        <td colspan="100" class="text-center py-5">
          <EmptyBody :isSearch="isSearch" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import handleFiles from "@/mixins/handleFiles.js"
import { formatDateDMY, formatDateHM } from "@/helpers/dateFormater.js"
import objHandlerMixins from "@/mixins/objHandlerMixins.js"
import {
  parseUsersStatus,
  parseUsersStatusTextClass,
} from "@/helpers/FormatnParse.js"
import { mapState } from "vuex"
import CopyButton from "../Layout/CopyButton.vue"
export default {
  mixins: [objHandlerMixins, handleFiles],
  props: {
    listData: {
      type: Array,
      default() {
        return []
      },
    },
    isFirstLoading: {
      type: Boolean,
      default: true,
    },
    loadingTable: {
      type: Boolean,
      default: true,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      companies: (state) => state.users.companies,
      listUsers: (state) => state.users.listUsers,
    }),
    listDataComputed() {
      return this.listData.map((o) => ({
        ...o,
        department_computed: o.department ? o.department : null,
        company_computed:
          o.department && o.department.company_id
            ? this._arrayFilter(this.companies, "id", o.department.company_id)
            : null,
        creator_computed: o.creator_id
          ? this._arrayFilter(this.listUsers, "id", o.creator_id)
          : null,
      }))
    },
  },
  methods: {
    formatDateHM,
    formatDateDMY,
    parseUsersStatus(_d) {
      return parseUsersStatus(_d)
    },
    parseUsersStatusTextClass(_d) {
      return parseUsersStatusTextClass(_d)
    },
    viewDetail(data) {
      if (data && data.file) {
        const a = document.createElement("a")
        a.setAttribute("href", data.file.url)
        a.setAttribute("target", "_blank")
        a.setAttribute("download", data.file.original_name)
        document.body.appendChild(a)
        a.click()
        a.remove()
      }
    },
  },
  components: { CopyButton },
}
</script>
<style lang="scss" scoped>
table {
  border-radius: 8px;
  overflow: hidden;
}
.users-table {
  tbody {
    td {
      font-size: 14px !important;
    }
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 3;
    border-radius: 8px !important;
  }
}
.v-application {
  .body-loading {
    opacity: 0.8;
    /* background-color: rgba(239, 240, 240, 0.563) !important; */
    pointer-events: none;
    position: relative;
  }
  .absolute-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    min-height: 200px;
    background-color: rgba(239, 240, 240, 0.563) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style lang="scss" scoped>
.btn-copy {
  opacity: 0;
}
.hover-show-copy:hover .btn-copy {
  opacity: 1;
}
</style>
