const repo = (axios) => {
  return {
    getListData(payload) {
      return axios
        .get(
          `/api/admin/expense-management/expense-categories?include=position_count,user_count`,
          {
            params: { ...payload },
          },
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getDetailData(id, payload) {
      return axios
        .get(`/api/admin/expense-management/expense-categories/${id}`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },

    update(id, payload) {
      return axios
        .put(`/api/admin/expense-management/expense-categories/${id}`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    create(payload) {
      return axios
        .post(`/api/admin/expense-management/expense-categories`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    updateStatus(id, payload) {
      return axios
        .post(
          `/api/admin/expense-management/expense-categories/${id}/status`,
          payload,
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    delete(_id) {
      return axios({
        method: "DELETE",
        url: `/api/admin/expense-management/expense-categories/${_id}`,
      })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
  }
}
export default repo
