<template>
  <v-container class="page-container p-3">
    <CustomerAccountsContainer />
  </v-container>
</template>

<script>
import CustomerAccountsContainer from "@/components/Container/WarehouseManagement/CustomerAccountsContainer.vue"
export default {
  components: { CustomerAccountsContainer },
}
</script>
