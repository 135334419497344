<template>
  <v-container class="page-container p-3">
    <WarehouseConfigContainer />
  </v-container>
</template>

<script>
import WarehouseConfigContainer from "@/components/Container/WarehouseConfigContainer.vue"
export default {
  components: { WarehouseConfigContainer },
}
</script>
