<template>
  <v-container class="page-container p-3">
    <LocationsContainer />
  </v-container>
</template>

<script>
import LocationsContainer from "@/components/Container/WarehouseManagement/LocationsContainer.vue"
export default {
  components: { LocationsContainer },
}
</script>
