import Vue from "vue"
import Vuex from "vuex"
import toast from "./toast"
import news from "./news"
import users from "./users"
import api from "@/services"
import { deleteCookie, setCookie } from "@/helpers/customizeCookie.js"

import en from "@/plugins/locales/en.json"
import vi from "@/plugins/locales/vi.json"
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    socket: {
      isConnected: false,
      message: "",
      reconnectError: false,
      loggedInUser: {},
      isLogging: false,
      errorLogging: "",
      isReconnecting: false,
    },
    auth: {
      isAuth: false,
      user: null,
      token: null,
    },
    showLogoutPopup: false,
    sideBarAppMini: false,
    sideBarContactMini: false,
    listImagePreview: [],
    listUserReadOnly: [],
    listPermission: [],
    listWarehouseEximTypes: [],
    listTranshipmentForm: [],
    listWarehousePackagingSpecifications: [],
    listGoodTypes: [],
    listConditionTypes: [],
    customerCompanyStatus: [
      {
        id: 10,
        name: "Đang hoạt động",
      },
      {
        id: 20,
        name: "Ngừng hoạt động",
      },
    ],
    listUserReadOnlyName: "",
    selectedImgIndex: 0,
    currentRightSideActive: 1,
    currentRightSideTab: 0,
    selectedUser: {},
    selectedUserGroup: {},
    listVehicleTypes: [],
    listCustomerCompanies: [],
    listGates: [],
    listUnits: [],
    listRevenueTypes: [],
    splitMark: "$#%8$H%",
    currentLanguage: "vi", // vi | en
  },
  mutations: {
    setLang(state, payload) {
      state.currentLanguage = payload
    },
    setCurrentRightSideTab(state, number) {
      state.currentRightSideTab = number
    },
    setCurrentRightSideActive(state, number) {
      state.currentRightSideActive = number
    },
    setSelectedUser(state, record) {
      state.selectedUser = record
    },
    setSelectedUserGroup(state, record) {
      state.selectedUserGroup = record
    },
    setListVehicleTypes(state, payload) {
      state.listVehicleTypes = payload
    },
    setListCompanies(state, payload) {
      state.listCustomerCompanies = payload
    },
    setListGates(state, payload) {
      state.listGates = payload
    },
    setListUnits(state, payload) {
      state.listUnits = payload
    },
    setListRevenueTypes(state, payload) {
      state.listRevenueTypes = payload
    },
    setListWarehouseEximTypes(state, payload) {
      state.listWarehouseEximTypes = payload
    },
    setListTranshipmentForm(state, payload) {
      state.listTranshipmentForm = payload
    },
    setListGoodTypes(state, payload) {
      state.listGoodTypes = payload
    },
    setListConditionTypes(state, payload) {
      state.listConditionTypes = payload
    },
    setListWarehousePackagingSpecifications(state, payload) {
      state.listWarehousePackagingSpecifications = payload
    },
    setListImagePreview(state, payload) {
      state.listImagePreview = payload
    },
    setSelectedImgIndex(state, payload) {
      state.selectedImgIndex = payload
    },
    SET_LISTUSER_READONLY_POPUP(state, payload) {
      state.listUserReadOnly = [...payload]
    },
    SET_LISTUSER_READONLY_POPUPNAME(state, payload) {
      state.listUserReadOnlyName = payload
    },
    SET_USER_LOGGEDIN(state, payload) {
      state.auth = { ...payload }
    },
    HOTSET_USER(state, payload) {
      state.auth = { ...state.auth, user: payload }
    },
    SET_LOGOUT_USER(state) {
      deleteCookie("token")
      state.auth = { isAuth: false, user: null, token: null }
    },
    SET_LOGOUT_POPUP(state, payload) {
      state.showLogoutPopup = payload
    },
    setCurrentToken(state, payload) {
      // localStorage.setItem("token", payload)
      setCookie("token", payload, 30)
      state.currentToken = payload
    },
    setSidebarMini(state, payload) {
      state.sideBarAppMini = payload
    },
    setSidebarContactMini(state, payload) {
      state.sideBarContactMini = payload
    },
    // SOCKET HANDLER
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget
      state.socket.isConnected = true
      if (state.socket.isReconnecting) {
        state.socket.isReconnecting = false
      }
      Vue.prototype.$socket.send(
        JSON.stringify({
          msg: "connect",
          version: "1",
          support: ["1"],
        }),
      )
    },
    SOCKET_ONCLOSE(state) {
      state.socket.isConnected = false
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      state.socket.isReconnecting = true
      console.info(state.socket, count, "reconnect socket")
      if (count >= 3) {
        // window.location.href = '/';
      }
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true
    },
    //
  },
  getters: {
    isAuth: (state) => {
      return state.auth.isAuth
    },
    token: (state) => {
      return state.auth.token
    },
    userFromAuth: (state) => {
      if (!state.auth.user) {
        return {}
      }
      return state.auth.user
    },
    userPermissions: (state) => {
      if (!state.auth.user) {
        return []
      }
      return state.auth.user.application_access_permissions || []
    },
    sysLanguage: (state) => {
      // languageObj
      if (state.currentLanguage === "en") {
        return en
      }
      return vi
    },
    permissionsByGroup: (state) => {
      const listPermission = state.listPermission.map((o) => ({
        ...o,
        group: o.group || "Không rõ",
      }))
      const listGroup = [...new Set(listPermission.map((o) => o.group))]
      return listGroup.map((o) => {
        return {
          group: o,
          items: listPermission.filter((k) => k.group === o),
        }
      })
    },
  },
  actions: {
    getListPermission() {
      api.roles
        .getListPermission({ pagination: false })
        .then((res) => {
          const rs = res
          const data = rs.data.permissions
          this.listPermission = data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async getListVehicleTypes({ commit }) {
      const res = await api.internal.getListVehicleTypes({ pagination: false })
      if (!res) {
        commit("toast/getError", this.sysLanguage.snackbar.getUserGroupFail)
        return
      }
      try {
        if (res.status && res.status >= 400) {
          commit("toast/getError", res.data.message)
          return
        }
        commit("setListVehicleTypes", res.data.data.items)
      } catch (error) {
        commit("toast/getError", `${error}`)
      }
    },
    async getListCustomerCompany({ commit }) {
      const res = await api.internal.getListCustomerCompany({
        pagination: false,
      })
      if (!res) {
        commit("toast/getError", this.sysLanguage.snackbar.getUserGroupFail)
        return
      }
      try {
        if (res.status && res.status >= 400) {
          commit("toast/getError", res.data.message)
          return
        }
        commit("setListCompanies", res.data.data.companies)
      } catch (error) {
        commit("toast/getError", `${error}`)
      }
    },
    async getListGates({ commit }) {
      const res = await api.internal.getListGates({
        pagination: false,
      })
      if (!res) {
        commit("toast/getError", this.sysLanguage.snackbar.getUserGroupFail)
        return
      }
      try {
        if (res.status && res.status >= 400) {
          commit("toast/getError", res.data.message)
          return
        }
        commit("setListGates", res.data.data.items)
      } catch (error) {
        commit("toast/getError", `${error}`)
      }
    },
    async getListUnits({ commit }) {
      const res = await api.internal.getListUnits({
        pagination: false,
      })
      if (!res) {
        commit("toast/getError", this.sysLanguage.snackbar.getUserGroupFail)
        return
      }
      try {
        if (res.status && res.status >= 400) {
          commit("toast/getError", res.data.message)
          return
        }
        commit("setListUnits", res.data.data.items)
      } catch (error) {
        commit("toast/getError", `${error}`)
      }
    },
    async getListRevenueTypes({ commit }) {
      const res = await api.internal.getListRevenueTypes({
        pagination: false,
      })
      if (!res) {
        commit("toast/getError", this.sysLanguage.snackbar.getUserGroupFail)
        return
      }
      try {
        if (res.status && res.status >= 400) {
          commit("toast/getError", res.data.message)
          return
        }
        commit("setListRevenueTypes", res.data.data.items)
      } catch (error) {
        commit("toast/getError", `${error}`)
      }
    },
    // KHO
    async getListEximTypes({ commit }) {
      const res = await api.internal.getListEximTypes({
        pagination: false,
      })
      if (!res) {
        commit("toast/getError", this.sysLanguage.snackbar.getUserGroupFail)
        return
      }
      try {
        if (res.status && res.status >= 400) {
          commit("toast/getError", res.data.message)
          return
        }
        commit("setListWarehouseEximTypes", res.data.data.items)
      } catch (error) {
        commit("toast/getError", `${error}`)
      }
    },
    async getListTranshipmentForms({ commit }) {
      const res = await api.internal.getListTranshipmentForms({
        pagination: false,
      })
      if (!res) {
        commit("toast/getError", this.sysLanguage.snackbar.getUserGroupFail)
        return
      }
      try {
        if (res.status && res.status >= 400) {
          commit("toast/getError", res.data.message)
          return
        }
        commit("setListTranshipmentForm", res.data.data.items)
      } catch (error) {
        commit("toast/getError", `${error}`)
      }
    },
    async getListWarehousePackagingSpecifications({ commit }) {
      const res = await api.internal.getListWarehousePackagingSpecifications({
        pagination: false,
      })
      if (!res) {
        commit("toast/getError", this.sysLanguage.snackbar.getUserGroupFail)
        return
      }
      try {
        if (res.status && res.status >= 400) {
          commit("toast/getError", res.data.message)
          return
        }
        commit("setListWarehousePackagingSpecifications", res.data.data.items)
      } catch (error) {
        commit("toast/getError", `${error}`)
      }
    },
    async getListGoodTypes({ commit }) {
      const res = await api.internal.getListGoodTypes({
        pagination: false,
      })
      if (!res) {
        commit("toast/getError", this.sysLanguage.snackbar.getUserGroupFail)
        return
      }
      try {
        if (res.status && res.status >= 400) {
          commit("toast/getError", res.data.message)
          return
        }
        commit("setListGoodTypes", res.data.data.items)
      } catch (error) {
        commit("toast/getError", `${error}`)
      }
    },
    async getListConditionTypes({ commit }) {
      const res = await api.internal.getListConditionTypes({
        pagination: false,
      })
      if (!res) {
        commit("toast/getError", this.sysLanguage.snackbar.getUserGroupFail)
        return
      }
      try {
        if (res.status && res.status >= 400) {
          commit("toast/getError", res.data.message)
          return
        }
        commit("setListConditionTypes", res.data.data.items)
      } catch (error) {
        commit("toast/getError", `${error}`)
      }
    },
  },
  modules: { toast, news, users },
})
