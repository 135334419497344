<template>
  <div style="background-color: #f2f8fa">
    <v-row v-if="detailData" class="m-0 p-0 pt-1 news-feed-row align-start">
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Tên cấu hình</p>
          <p class="text-neutral-800 fw-500">{{ detailData.name }}</p>
        </div>
      </v-col>
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Cửa khẩu</p>
          <p class="text-neutral-800 fw-500">
            {{ detailData.gate ? detailData.gate.name : "-/-" }}
          </p>
        </div>
      </v-col>
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Loại hình XNK</p>
          <p class="text-neutral-800 fw-500">
            {{ detailData.exim_type ? detailData.exim_type.name : "-/-" }}
          </p>
        </div>
      </v-col>
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Hình thức sang tải</p>
          <p class="text-neutral-800 fw-500">
            {{
              detailData.transhipment_form
                ? detailData.transhipment_form.name
                : "-/-"
            }}
          </p>
        </div>
      </v-col>
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Quy cách đóng gói</p>
          <p class="text-neutral-800 fw-500">
            {{
              detailData.packaging_specification
                ? detailData.packaging_specification.name
                : "-/-"
            }}
          </p>
        </div>
      </v-col>
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Loại hàng</p>
          <p class="text-neutral-800 fw-500">
            {{ detailData.goods_type ? detailData.goods_type.name : "-/-" }}
          </p>
        </div>
      </v-col>
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Ngày áp dụng</p>
          <p class="text-neutral-800 fw-500">
            {{ formatDateDMY(detailData.apply_date) }}
          </p>
        </div>
      </v-col>

      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Trọng tải xe áp dụng</p>
          <p class="text-neutral-800 fw-500">
            {{
              Array.isArray(detailData.vehicle_types)
                ? detailData.vehicle_types.map((o) => o.name).join(", ")
                : "-/-"
            }}
          </p>
        </div>
      </v-col>
      <v-col cols="6" class="py-1">
        <div>
          <p class="mb-2">Điều kiện áp dụng</p>

          <div
            v-for="item in detailData.conditions"
            :key="item.id"
            class="d-flex gap-2"
          >
            <p class="text-neutral-800 fw-500">{{ item.min_quantity }}</p>
            <p class="text-neutral-800 fw-500">
              &lt;
              {{ item.condition_type ? item.condition_type.name : "-/-" }}
              &lt;
            </p>
            <p class="text-neutral-800 fw-500">{{ item.max_quantity }}</p>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { formatDateDMY } from "@/helpers/dateFormater.js"
export default {
  props: {
    detailData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    formatDateDMY,
  },
}
</script>

<style></style>
