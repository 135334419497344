const repo = (axios) => {
  return {
    getListData(payload) {
      return axios
        .get(`/api/admin/expense-management/wh-configurations`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getListFileImports(payload) {
      return axios
        .get(`/api/admin/expense-management/wh-imports`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getDetailData(id, payload) {
      return axios
        .get(`/api/admin/expense-management/wh-configurations/${id}`, {
          params: { ...payload },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getDefaultExpenseItems(id, payload) {
      return axios
        .get(
          `/api/admin/expense-management/wh-configurations/${id}/default-expense-items`,
          {
            params: { ...payload },
          },
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    getDefaultRevenueItems(id, payload) {
      return axios
        .get(
          `/api/admin/expense-management/wh-configurations/${id}/default-revenue-items`,
          {
            params: { ...payload },
          },
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    linkRevenue(id, payload) {
      return axios
        .post(
          `/api/admin/expense-management/wh-configurations/${id}/link-revenue`,
          payload,
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    configExpenseItems(id, payload) {
      return axios
        .post(
          `/api/admin/expense-management/wh-configurations/${id}/expense-items`,
          payload,
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    configRevenueItems(id, payload) {
      return axios
        .post(
          `/api/admin/expense-management/wh-configurations/${id}/revenue-items`,
          payload,
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    update(id, payload) {
      return axios
        .put(`/api/admin/expense-management/wh-configurations/${id}`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    create(payload) {
      return axios
        .post(`/api/admin/expense-management/configurations`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    imports(payload) {
      return axios
        .post(`/api/admin/expense-management/wh-imports`, payload)
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    updateStatus(id, payload) {
      return axios
        .post(
          `/api/admin/expense-management/wh-configurations/${id}/status`,
          payload,
        )
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
    delete(_id) {
      return axios({
        method: "DELETE",
        url: `/api/admin/expense-management/wh-configurations/${_id}`,
      })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })
    },
  }
}
export default repo
