<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
        class="text-primary-500 pointer icon-copy"
        :class="className"
        size="18"
        @click="handleCopy"
        >{{ iconName }}</v-icon
      >
    </template>
    <span>Copy</span>
  </v-tooltip>
</template>
<script>
export default {
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      iconName: "mdi-content-copy",
      className: "cursor-pointer",
    }
  },

  methods: {
    copy(_val) {
      navigator.clipboard.writeText(_val)
    },
    handleCopy() {
      this.iconName = "mdi-check-all"
      this.className = "cursor-pointer text-color-01"
      this.copy(`${this.content}`)
      setTimeout(() => {
        this.iconName = "mdi-content-copy"
        this.className = "cursor-pointer"
      }, 2000)
    },
  },
}
</script>
